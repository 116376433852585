import { Key, useContext, useEffect, useState } from "react";
import {
  Center,
  Container,
  Flex,
  Title,
  Text,
  Button,
  Grid,
  Group,
  Space,
  Card,
  NumberInput,
  Image
} from "@mantine/core";
import { ValuesContext } from "../../ValuesContext";
import useDocumentTitle from "../../useDocumentTitle";
import { TotalPrice } from "./utils";
import { getConstants } from "../../constants";
import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

const Step4 = (props: {
  productData: any;
  active: number;
  nbNight: number;
  Hotel_id: number;
  Petitdej: any
  handleStepChange(nextStep: number): any;
}) => {
  const constants = getConstants(props.Hotel_id);

  const { values, setValues } = useContext(ValuesContext);
  const [alert, setlAlert] = useState(false);
  const petitdej = props.Petitdej;

  const [selectedRestauration, setSelectedRestauration] = useState<any>({
    id: values.Restauration.ProductId,
    name: values.Restauration.ProductName,
    price: values.Restauration.ProductPrice,
    chargingMode: values.Restauration.ChargingMode,
    Count: 1
  });
  const [selectedAdditionalProducts, setSelectedAdditionalProducts] = useState<
    any[]
  >(values.AdditionalProducts);

  useEffect(() => {
    if (selectedRestauration.id === '') {

      const restau = {
        id: petitdej.Id,
        name: petitdej.ExternalName,
        price: petitdej.Price.Value,
        chargingMode: petitdej.ChargingMode,
        Count: 1
      }

      setSelectedRestauration(restau);

      updateStep4Values(
        restau,
        selectedAdditionalProducts
      );
    }

    setValues((prevValues) => ({
      ...prevValues,
      TotalPrice: TotalPrice(values),
    }));
  }, [setValues, values]);

  const updateStep4Values = async (item1: any, selectedAdditionalProducts: any[]) => {
    let price1 = item1.price;
    let additionalProductsCost = 0;
    let Restauration: {
      ProductId: any;
      ProductName: any;
      ProductPrice: number;
      ChargingMode: any;
      Count: number;
    };
    let AdditionalProducts: any[];

    Restauration = {
      ProductId: item1.id,
      ProductName: item1.name,
      ProductPrice: price1,
      ChargingMode: item1.chargingMode,
      Count: 1
    };

    // Ajoute les produits supplémentaires sélectionnés à values.Step4.AdditionalProducts
    AdditionalProducts = selectedAdditionalProducts;

    selectedAdditionalProducts.forEach((product) => {
      switch (product.chargingMode) {
        case "PerPersonPerTimeUnit":
          additionalProductsCost +=
            product.price *
            (values.Step1.NbCuriste !== "1" || values.Step1.Accompagnant === 1
              ? 2
              : 1) *
            props.nbNight * product.Count;
          break;
        case "PerTimeUnit":
          additionalProductsCost += product.price * props.nbNight * product.Count;
          break;
        case "PerPerson":
          additionalProductsCost +=
            product.price *
            (values.Step1.NbCuriste !== "1" || values.Step1.Accompagnant === 1
              ? 2
              : 1) * product.Count;
          break;
        case "Once":
          additionalProductsCost += product.price * product.Count;
          break;
        default:
          break;
      }
    });

    let prixrestau = 0

    switch (Restauration.ChargingMode) {
      case "PerPersonPerTimeUnit":
        prixrestau =
          Restauration.ProductPrice *
          (values.Step1.NbCuriste !== "1" || values.Step1.Accompagnant === 1
            ? 2
            : 1) *
          props.nbNight;
        break;
      case "PerTimeUnit":
        prixrestau =
          Restauration.ProductPrice * props.nbNight;
        break;
      case "PerPerson":
        prixrestau =
          Restauration.ProductPrice *
          (values.Step1.NbCuriste !== "1" || values.Step1.Accompagnant === 1
            ? 2
            : 1);
        break;
      case "Once":
        prixrestau =
          Restauration.ProductPrice
        break;
      default:
        break;
    }

    await setValues((prevValues) => ({
      ...prevValues,
      Restauration: Restauration,
      AdditionalProducts: AdditionalProducts,
      AdditionalProductsPrice: additionalProductsCost + prixrestau
    }));
  };


  const handleAdditionalProductSelection = (product: any, newProduct: boolean) => {
    // Créer une variable temporaire pour stocker les modifications
    let updatedAdditionalProducts = [...selectedAdditionalProducts];

    // Vérifier si le produit est déjà dans le tableau
    const existingProduct = selectedAdditionalProducts.find(
      (item) => item.ProductId === product.ProductId
    );

    // Si le produit est déjà dans le tableau, le retirer
    if (existingProduct && !newProduct) {
      updatedAdditionalProducts = updatedAdditionalProducts.filter(
        (item) => item.ProductId !== product.ProductId
      );
    } else if (existingProduct && newProduct) {
      existingProduct.Count = product.Count
    }

    // Sinon, ajouter le produit au tableau
    else {
      updatedAdditionalProducts.push(product);
    }


    updateStep4Values(
      selectedRestauration,
      updatedAdditionalProducts
    );

    setSelectedAdditionalProducts(updatedAdditionalProducts)
  };

  const getChargingModeText = (chargingMode: string) => {
    switch (chargingMode) {
      case "PerPersonPerTimeUnit":
        return "/ Nuit / Pers";
      case "PerTimeUnit":
        return "/ Nuit";
      case "PerPerson":
        return "/ Pers";
      case "Once":
        return "";
      default:
        return "";
    }
  };

  useDocumentTitle(`Choix des produits - ${values.Hotel.name}`);
  return (
    <div className="select-zone">
      <Center>
        <Title order={3}>Options et Personnalisation</Title>
      </Center>
      <Space h="xl" />
      <Space h="xl" />
      <Container>
        <Title order={2}>RESTAURATION</Title>
      </Container>
      <Space h="xl" />


      {props.productData
        .filter(
          (i: any) =>
            i.AccountingCategoryId === "edc4fec1-bbdd-4fcc-82d3-adb300e3c573" ||
            i.AccountingCategoryId === "1055d3d3-6b62-4dd7-bc5d-ad7400a43e76" ||
            i.AccountingCategoryId === "84d3774e-9dd8-4a5a-a644-ad7400a43e76" ||
            i.AccountingCategoryId === "917b7af5-a423-4446-b26e-adaa00f3da28" ||
            i.AccountingCategoryId === constants.categorie_id ||
            i.Id === props.Petitdej.Id
        )
        .map((product: any, key: Key) => (
          <>
            <Space h="xl" />

            <Card
              sx={(theme) => ({
                backgroundColor:
                  selectedRestauration?.id === product.Id
                    ? theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : theme.colors.gray[1]
                    : theme.colors.white,
              })}
              key={key}
            >
              <Grid align="center" mx={10}>

                {product.imageUrl && window.innerWidth >= 800 && (
                  <Grid.Col span={2} md={2} lg={2} pl={"xs"}>
                    <Image maw={100} mx="auto" radius="md" pl={0} src={product.imageUrl} alt={product.ExternalName} />
                  </Grid.Col>
                )}
                <Grid.Col
                  span={product.imageUrl && window.innerWidth >= 800 ? 6 : 8}
                  md={10}
                  lg={product.imageUrl && window.innerWidth >= 800 ? 6 : 8}
                >
                  <Card.Section>
                    <Text className={"main-detail"}>{product.ExternalName}</Text>
                    <Text>{product.Description}</Text>
                  </Card.Section>
                </Grid.Col>
                <Grid.Col span={4} md={12} lg={4}>
                  <Card.Section>
                    <Flex align="flex-end" direction="column">
                      <Flex align="center" direction="row" gap="xs">
                        {product.Id === constants.petit_dejeuner_id ? (
                          <Text className={"price"}> {" "} Inclus</Text>
                        ) : (
                          <>
                            <Text className={"price"}>{product.Price.Value.toFixed(2).replace(".", ",")} €</Text>
                            <Text className={"from-price"}>
                              {getChargingModeText(product.ChargingMode)}
                            </Text>
                          </>
                        )}
                      </Flex>
                      <Button
                        className={
                          product.Id === constants.petit_dejeuner_id
                            ? "night-nav-active"
                            : selectedAdditionalProducts.find(
                              (p) => p.ProductId === product.Id
                            )
                              ? "night-nav-active"
                              : "night-nav"
                        }
                        onClick={() => {
                          if (product.Id !== constants.petit_dejeuner_id) {
                            handleAdditionalProductSelection({
                              ProductId: product.Id,
                              ProductName: product.ExternalName,
                              price: product.Price.Value,
                              chargingMode: product.ChargingMode,
                              Count: 1
                            }, false);
                          }
                        }}

                      >
                        {product.Id === constants.petit_dejeuner_id
                          ? "Sélectionné"
                          : selectedAdditionalProducts.find(
                            (p) => p.ProductId === product.Id
                          )
                            ? "Sélectionné"
                            : "Choisir"}
                      </Button>
                    </Flex>
                  </Card.Section>
                </Grid.Col>
              </Grid>
            </Card>
          </>
        ))}

      <Space h="xl" />
      <Container>
        <Title order={2}>AMELIOREZ VOTRE SEJOUR</Title>
      </Container>
      {props.productData
        .filter(
          (i: any) =>
            i.AccountingCategoryId !== "edc4fec1-bbdd-4fcc-82d3-adb300e3c573" &&
            i.AccountingCategoryId !== "1055d3d3-6b62-4dd7-bc5d-ad7400a43e76" &&
            i.AccountingCategoryId !== "84d3774e-9dd8-4a5a-a644-ad7400a43e76" &&
            i.AccountingCategoryId !== "917b7af5-a423-4446-b26e-adaa00f3da28" &&
            i.Id !== props.Petitdej.Id &&
            i.AccountingCategoryId !== constants.categorie_id
        )
        .map((product: any, key: Key) => (
          <Card key={key}>
            <Grid align="center" mx={0}>
              {product.imageUrl && window.innerWidth >= 800 && (
                <Grid.Col span={2} md={2} lg={2} pl={"xs"}>
                  <Image maw={100} mx="auto" radius="md" pl={0} src={product.imageUrl} alt={product.ExternalName} />
                </Grid.Col>
              )}

              <Grid.Col
                span={product.imageUrl && window.innerWidth >= 800 ? 7 : 9}
                md={10}
                lg={product.imageUrl && window.innerWidth >= 800 ? 7 : 9}
              >
                <Card.Section mt={10} pl={"md"}>
                  <Text className={"main-detail"}>{product.ExternalName}</Text>
                  <Text>{product.Description}</Text>
                </Card.Section>
              </Grid.Col>
              <Grid.Col span={3} md={12} lg={3}>
                <Card.Section>
                  <Flex align="flex-end" direction="column">
                    <Flex align="center" direction="row" gap="xs">
                      <Text className={"price"}>{product.Price.Value.toFixed(2).replace(".", ",")} €</Text>
                      <Text className={"from-price"}>
                        {getChargingModeText(product.ChargingMode)}
                      </Text>
                      {selectedAdditionalProducts.find(
                        (p) => p.ProductId === product.Id
                      ) && product.ChargingMode !== "PerTimeUnit" && product.ChargingMode !== "PerPersonPerTimeUnit"
                        ?

                        <NumberInput
                          defaultValue={selectedAdditionalProducts.find(
                            (p) => p.ProductId === product.Id
                          ).Count}
                          max={Number(values.nbNight) * Number(values.Step1.NbCuriste)}
                          min={1}
                          size="sm"
                          style={{ width: '60px' }}
                          inputMode="none"
                          onChange={(newValue) =>
                            handleAdditionalProductSelection({
                              ProductId: product.Id,
                              ProductName: product.ExternalName,
                              price: product.Price.Value,
                              chargingMode: product.ChargingMode,
                              Count: newValue
                            }, true)
                          }

                        />
                        : null}

                    </Flex>
                    <Space h="md" />
                    <Button
                      className={
                        selectedAdditionalProducts.find(
                          (p) => p.ProductId === product.Id
                        )
                          ? "night-nav-active"
                          : "night-nav"
                      }
                      onClick={() => {
                        handleAdditionalProductSelection({
                          ProductId: product.Id,
                          ProductName: product.Name,
                          price: product.Price.Value,
                          chargingMode: product.ChargingMode,
                          Count: 1
                        }, false);
                      }}
                      uppercase
                    >
                      {selectedAdditionalProducts.find(
                        (p) => p.ProductId === product.Id
                      )
                        ? "Sélectionné"
                        : "Choisir"}
                    </Button>
                  </Flex>
                </Card.Section>
              </Grid.Col>
            </Grid>
          </Card>
        ))
      }
      <Space h="xl" />
      <Space h="xl" />
      {
        alert && (
          <Alert icon={<IconAlertCircle size="1rem" />} title="Erreur !" color="red" mb={20}>
            Veuillez choisir les formules de restauration.
          </Alert>
        )
      }
      <Group position="center" my="xl">

        <Button
          className={"step-btn-previous"}
          onClick={() => props.handleStepChange(props.active - 1)}
        >
          ← Retour
        </Button>

        <Button
          className={"night-nav-active"}
          onClick={() => {
            if (selectedRestauration.id) {
              updateStep4Values(
                selectedRestauration,
                selectedAdditionalProducts
              );
              props.handleStepChange(props.active + 1);
            } else {
              setlAlert(true)
            }
          }}
        >
          Suivant →
        </Button>
      </Group>

    </div >
  );
};
export default Step4;
