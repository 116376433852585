import {
  Center,
  Container,
  Flex,
  Radio,
  Title,
  Text,
  Group,
  Button,
  Space,
  Card,
  Badge,
  Grid,
  Col,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Key, SetStateAction, useContext, useEffect, useState } from "react";
import { faWater } from "@fortawesome/free-solid-svg-icons";
import { ValuesContext } from "../../ValuesContext";
import useDocumentTitle from "../../useDocumentTitle";
import { TotalPrice } from "./utils";
import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

const Step2 = (props: {
  heal: string;
  cures: any;
  cureName: string;
  idCure: number;
  active: number;
  nbNight: number;
  Petitdej: any;
  handleStepChange(number: number): any;
}) => {
  const { values, setValues } = useContext(ValuesContext);
  const [healValue, setHealValue] = useState<string>(values.Step2.Value);
  const [cureValue, setCureValue] = useState<any>({
    cureName: "",
    price: 0,
    Mews_id: ""
  });
  const [alert, setlAlert] = useState(false);
  const [selectedCure, setSelectedCure] = useState('');

  const toggleDescription = (cureName: SetStateAction<string>) => {
    setSelectedCure(cureName === selectedCure ? '' : cureName);
  };

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      TotalPrice: TotalPrice(values),
    }));
  }, [setValues, values]);


  function changeoption(value: string) {
    setHealValue(value)
    updateStep2Values(value, cureValue)
  }

  // Renvoie la description filtrer sans le premier p et la derniere div (video)
  function Description(desc: string) {
    const endIndex = desc.indexOf('</p>');

    const remainingText = desc.substring(endIndex + 4);

    const divIndex = remainingText.lastIndexOf('<div');

    let finalText = remainingText
    if (divIndex > -1) {
      finalText = remainingText.substring(0, divIndex);
    }

    return finalText;
  }


  const updateStep2Values = async (value: string, cureValue: any) => {
    let newStep2Values: any;
    const oldprice = values.CurePrice;

    if (value !== "") {
      if (value === "1") {
        newStep2Values = {
          Value: value,
          CureName: props.cureName,
          Price: oldprice,
          Mews_id: values.Mews_id
        };
      } else {
        newStep2Values = {
          Value: value,
          CureName: cureValue.cureName,
          Price: cureValue.price,
          Mews_id: cureValue.Mews_id
        };
      }
    } else {
      newStep2Values = {
        Value: "",
        CureName: "",
        Price: 0,
      };
    }
    setValues((prevValues) => ({
      ...prevValues,
      Step2: newStep2Values,
    }));

  };
  const backStep = (value: string, cureValue: any) => {
    updateStep2Values(value, cureValue);
    props.handleStepChange(props.active - 1);
  };
  const nextStep = (value: string, cureValue: any) => {
    updateStep2Values(value, cureValue);
    if (props.cures[0].hotel_id === 1) {
      props.handleStepChange(props.active + 3);
    } else {
      props.handleStepChange(props.active + 1);
    }

  };

  useDocumentTitle(`Programmes des soins - ${values.Hotel.name}`);
  return (
    <div className="select-zone">
      <Center>
        <Title order={3}>Programmes des soins</Title>
      </Center>
      <Space h="xl" />
      <Space h="xl" />
      <Flex>
        {values.Step1.NbCuriste === "2" ? (
          <>
            <Container>
              <Grid gutter="lg">
                <Col span={12} lg={6}>
                  <div className="step-block">
                    <h4>CURISTE 1</h4>
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: Description(values.Description) }}></div>
                    </div>
                  </div>
                </Col>
                <Col span={12} lg={6}>
                  <div className="padding-10">
                    <h4 className="margin-y-0">CURISTE 2</h4>
                    <p>Souhaitez-vous effectuer le même programme de soins ?</p>
                    <Radio.Group
                      orientation="vertical"
                      value={healValue}
                      onChange={changeoption}
                    >
                      <Radio
                        value="1"
                        label="Oui"
                        description="Nous effectuerons le même programme"
                      />
                      <Radio
                        value="2"
                        label="Non"
                        description="Je souhaite choisir un programme différent"
                      />
                    </Radio.Group>
                  </div>
                </Col>
              </Grid>
            </Container>
          </>
        ) : (
          <Container fluid className={"step-block"}>
            <Title order={4}>CURISTE 1</Title>
            <Text>
              <div dangerouslySetInnerHTML={{ __html: Description(values.Description) }}></div>
            </Text>
          </Container>
        )}
      </Flex>
      {healValue === "2" && (
        <>
          {props.cures && props.cures
            .sort((a: any, b: any) => a.nights - b.nights)
            .filter((items: any) => items.mews_id !== values.Mews_id)
            .map((items: any, key: Key) => (
              <>
                <Space h="md" />
                <Card
                  className={"curesection-block"}
                  sx={(theme) => ({
                    backgroundColor:
                      cureValue.cureName === items.title
                        ? theme.colorScheme === "dark"
                          ? theme.colors.dark[5]
                          : theme.colors.gray[1]
                        : theme.colors.white,
                  })}
                  key={key}
                >
                  <Grid align="flex-end">
                    <Grid.Col md={12} lg={8}>
                      <Card.Section>
                        <Text>
                          <h2 className={"main-title"}>{items.title}</h2>
                        </Text>
                        <Badge variant="filled">{items.category_title}</Badge>
                        <Space h="md" />
                        <Text>
                          {selectedCure !== items.title && (
                            <>
                              <div dangerouslySetInnerHTML={{ __html: items.description.split('</p>')[0] + '</p>' }}></div>
                              <Text
                                className={"main-detail"}
                                underline
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDescription(items.title)}
                              >
                                Voir plus de détails
                              </Text>
                            </>
                          )}

                          {selectedCure === items.title && (
                            <>
                              <div dangerouslySetInnerHTML={{ __html: items.description }}></div>
                              <Text
                                className={"main-detail"}
                                underline
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDescription(items.title)}
                              >
                                <Space h="xl" />
                                Voir moins de détail
                              </Text>
                            </>
                          )}
                        </Text>

                        <Space h="xl" />
                        <Space h="xl" />
                        <Space h="xl" />
                        <Space h="xl" />
                        <Text>
                          <FontAwesomeIcon
                            icon={faWater}
                            size="lg"
                            style={{ color: "#1dbecf" }}
                          />
                          {values.Hotel.id === 1 ? (
                            <>
                              &nbsp; Accès offert au bassin de relaxation l’Archipel et à l’espace Fitness.
                            </>
                          ) : (
                            <>
                              &nbsp; Accès OFFERT au Lagon, Club Fitness et Aqua’Sport.
                            </>
                          )}
                        </Text>
                      </Card.Section>
                    </Grid.Col>
                    <Grid.Col md={12} lg={4}>
                      <Card.Section>
                        <Flex align="flex-end" direction="column">
                          <>
                            <Text className={"from-price"}>A partir de</Text>
                            <Flex align="center" direction="row" gap="xs">
                              <Text className={"price"}>{(items.minPrice + (props.Petitdej.Price.GrossValue * props.nbNight)).toFixed(2).replace(".", ",")}€</Text>
                              <Text className={"from-price"}>/pers</Text>
                            </Flex>
                          </>
                          <Space h="md" />
                          <Button
                            className={
                              cureValue.cureName === items.title
                                ? "night-nav-active"
                                : "night-nav"
                            }
                            uppercase
                            onClick={async () => {
                              const cure = {
                                cureName: items.title,
                                price: items.minPrice,
                                Mews_id: items.mews_id
                              }
                              await setCureValue(cure);
                              updateStep2Values(healValue, cure)
                            }}
                          >
                            {cureValue.cureName === items.title ? "Sélectionné" : "Choisir"}
                          </Button>
                        </Flex>
                      </Card.Section>
                    </Grid.Col>
                  </Grid>
                </Card>
              </>
            ))}
        </>
      )}
      <Space h="xl" />
      {alert && (
        <Alert icon={<IconAlertCircle size="1rem" />} title="Erreur !" color="red" mb={20}>
          Veuillez choisir la cure du 2ème curiste.
        </Alert>
      )}
      <Group position="center" my="xl">

        <Button
          className={"step-btn-previous"}
          onClick={() => backStep("", "")}
        >
          ← Retour
        </Button>

        <Button
          className={"night-nav-active"}
          onClick={() => {
            if (values.Step1.NbCuriste === "2") {
              if (healValue === "1") {
                nextStep(healValue, cureValue);
              } else if (healValue === "2" && cureValue.cureName) {
                nextStep(healValue, cureValue);
              } else {
                setlAlert(true);
              }
            } else {
              nextStep(healValue, cureValue);
            }
          }}
        >
          Suivant →
        </Button>
      </Group>

    </div>
  );
};

export default Step2;
