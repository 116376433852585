const token = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const getHotel = async (hotel_id: number) => {
  const response = await fetch(`${API_URL}/hotel/${hotel_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const getAllHotel = async () => {
  const response = await fetch(`${API_URL}/hotel`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const getAllType = async () => {
  const response = await fetch(`${API_URL}/category`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const getHotelDestination = async (destination: number) => {
  const response = await fetch(`${API_URL}/hotel/destination/${destination}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const getAllDestination = async () => {
  const response = await fetch(`${API_URL}/city`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const hotelDataProvider = {
  getHotelId: async (hotel_id: number) => {
    return getHotel(hotel_id);
  },
  getHotelDest: async (destination: number) => {
    return getHotelDestination(destination);
  },
  geAllDest: async () => {
    return getAllDestination();
  },
  getAllHot: async () => {
    return getAllHotel();
  },
  getAllTyp: async () => {
    return getAllType();
  },
};

export default hotelDataProvider;
