const token = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;


const getAllCategoryNight = async () => {
  const response = await fetch(`${API_URL}/category`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+token,
    },
  });

  return response.json();
};


const categoryCureDataProvider = {
  getCategoryNight: async (nights: string) => {
    if (nights === "nights") {
      return getAllCategoryNight();
    }

    throw new Error(`Unsupported resource: ${nights}`);
  },
};

export default categoryCureDataProvider;
