import {
  Alert,
  Box,
  Card,
  Flex,
  Group,
  Text,
  Image,
  Space,
  Button,
} from "@mantine/core";

import banniere from "../images/plage_bab.jpg";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWater } from "@fortawesome/free-solid-svg-icons";
import { ValuesContext } from "../ValuesContext";

const RecapCard = (props: { date: any; nbNight: number; active: number }) => {
  const { values } = useContext(ValuesContext);
  const startDate = new Date(props.date[0]);
  const startDateISO = startDate.toLocaleString("fr-FR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const endDate = new Date(props.date[1]);
  const endDateISO = endDate.toLocaleString("fr-FR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return (
    <Card radius="xs">
      <Card.Section>
        <Image height={160} fit={"cover"} src={banniere} />
        <Space h="xl" />
      </Card.Section>
      <Card.Section>
        <Group position="center" spacing="xl">
          <Alert color="cyan" variant="outline">
            <Flex direction="column" align="center">
              <Text size="xs" align="center" className={"main-color"}>
                Arrivée le
              </Text>
              <Text className={"main-color"}>
                <strong>{startDateISO}</strong>
              </Text>
            </Flex>
          </Alert>
          <Alert color="cyan" variant="outline">
            <Flex direction="column" align="center">
              <Text size="xs" align="center" className={"main-color"}>
                Départ le
              </Text>
              <Text className={"main-color"}>
                <strong>{endDateISO}</strong>
              </Text>
            </Flex>
          </Alert>
        </Group>
      </Card.Section>
      <Space h="xl" />
      <Card.Section>
        <Space h="xl" />
        <Text className={"recap-card-title"}>

          {props.nbNight === 1 ? (
            <>{props.nbNight + 1} JOURS - {props.nbNight} NUIT</>
          ) : (
            <>{props.nbNight + 1} JOURS - {props.nbNight} NUITS</>
          )}


        </Text>
        <Space h="xs" />
        <Text className={"recap-card-text"}>{values.CureName}</Text>
        <Button className={"night-nav"} component="a" href="/" uppercase>
          Modifier le séjour
        </Button>
        <Space h="xl" />
        <Text className={"recap-card-title"}>Participant(s)</Text>
        <Space h="xs" />
        <Text className={"recap-card-text"}>Curiste 1 : {values.CureName}</Text>
        <Space h="xs" />
        <Text size="xs">
          <FontAwesomeIcon
            icon={faWater}
            size="xs"
            style={{ color: "#1dbecf" }}
          />
          {values.Hotel.id === 1 ? (
            <>
              &nbsp; Accès offert au bassin de relaxation l’Archipel et à l’espace Fitness.
            </>
          ) : (
            <>
              &nbsp; Accès OFFERT au Lagon, Club Fitness et Aqua’Sport.
            </>
          )}
        </Text>

        <Space h="md" />

        {values.Step1.Label === "2 curistes" ? (
          <>
            <Text className={"recap-card-text"}>
              Curiste 2 : {values.Step2.CureName}
            </Text>
            <Text size="xs">
              <FontAwesomeIcon
                icon={faWater}
                size="xs"
                style={{ color: "#1dbecf" }}
              />
              {values.Hotel.id === 1 ? (
                <>
                  &nbsp; Accès offert au bassin de relaxation l’Archipel et à l’espace Fitness.
                </>
              ) : (
                <>
                  &nbsp; Accès OFFERT au Lagon, Club Fitness et Aqua’Sport.
                </>
              )}
            </Text>
          </>
        ) : values.Step1.Label === "1 curiste et 1 accompagnant" ? (
          <Text className={"recap-card-text"}>{values.Step1.Label}</Text>
        ) : (
          ""
        )}

        <Space h="xl" />
      </Card.Section>
      <Card.Section>
        <Box
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            textAlign: "center",
            padding: theme.spacing.xl,
            borderRadius: theme.radius.xs,
          })}
        >
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "sm", sm: "lg" }}
            justify={{ base: "space-between", sm: "space-between" }}
          >
            <Text>
              <Space h="md" />

              <strong>Prix total</strong>
            </Text>
            <Flex align={{ base: "center", sm: "flex-end" }} direction="column">
              {props.active < 4 || values.Hotel.id === 1
                ? <Text className={"from-price"}>A partir de</Text>
                : <Text className={"from-price"}>‎ </Text>
              }
              <Flex align="center" direction="row" gap="xs">
                <Text className={"price"}>{values.TotalPrice}€</Text>
              </Flex>
            </Flex>


          </Flex>
        </Box>
      </Card.Section>
    </Card>
  );
};

export default RecapCard;
