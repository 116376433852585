const token = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const getAllPriceCure = async () => {
  const response = await fetch(`${API_URL}/price`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const getPriceByDates = async (
  room_id: number,
  start_date: string,
  end_date: string,
  stay_id: number
) => {
  const response = await fetch(
    `${API_URL}/bedroom_price/${room_id}/${start_date}/${end_date}/${stay_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.json();
};

const getAllPricesByDates = async (
  start_date: string,
  end_date: string,
  stay_id: any
) => {
  const response = await fetch(
    `${API_URL}/bedroom_price/${start_date}/${end_date}/${stay_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.json();
};

const getAllMyPrice = async (start_date: string, end_date: string) => {
  const response = await fetch(
    `${API_URL}/bedroom_price_all/${start_date}/${end_date}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.json();
};

const getAllRooms = async () => {
  const response = await fetch(`${API_URL}/room_category`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const getAllDiscount = async () => {
  const response = await fetch(`${API_URL}/discount`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const getRoomPrice = async (room_id: number) => {
  const response = await fetch(`${API_URL}/room_category/${room_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

const datesPriceDataProvider = {
  getPriceOption: async (price: string) => {
    if (price === "price") {
      return getAllPriceCure();
    }

    throw new Error(`Unsupported resource: ${price}`);
  },
  getPriceByDate: async (
    datePrice: string,
    room_id: number,
    start_date: string,
    end_date: string,
    stay_id: any
  ) => {

    if (datePrice === "datePrice") {
      return getPriceByDates(room_id, start_date, end_date, stay_id);
    }

    throw new Error(`Unsupported resource: ${datePrice}`);
  },
  getAllPriceByDate: async (
    datePrice: string,
    start_date: string,
    end_date: string,
    stay: number
  ) => {
    if (datePrice === "datePrice") {
      return getAllPricesByDates(start_date, end_date, stay);
    }

    throw new Error(`Unsupported resource: ${datePrice}`);
  },

  getMyPrice: async (
    datePrice: string,
    start_date: string,
    end_date: string
  ) => {
    if (datePrice === "datePrice") {
      return getAllMyPrice(start_date, end_date);
    }

    throw new Error(`Unsupported resource: ${datePrice}`);
  },

  getDiscount: async () => {
    return getAllDiscount();
  },

  getPriceByRoomCategory: async (roomPrice: string, room_id: number) => {
    if (roomPrice === "roomPrice") {
      return getRoomPrice(room_id);
    }

    throw new Error(`Unsupported resource: ${roomPrice}`);
  },
  getAllRoomCategory: async (roomPrice: string) => {
    if (roomPrice === "allRoom") {
      return getAllRooms();
    }

    throw new Error(`Unsupported resource: ${roomPrice}`);
  },
};

export default datesPriceDataProvider;
