export const getMinPrice = (
  priceArray: any[],
  startDate: string,
  endDate: string
) => {
  const prices = priceArray
    .filter((p) => p.start_date >= startDate && p.end_date <= endDate)
    .map((p) => p.price_excluding_tax);

  return prices.length > 0 ? Math.min(...prices) : 0;
};

export const sumPrice = (priceArray: any[]) => {
  const sum = priceArray.reduce((acc, price) => acc + price, 0);
  const formattedSum = sum.toFixed(2); // Limite le nombre de décimales à 2
  return formattedSum;
};

export const TotalPrice = (values: any) => {
  let prix_chambre = [0];

  //console.log(values.Discount)

  if (Object.keys(values.Step3.selectedRoom).length !== 0) {
    if (
      values.Step1.NbCuriste === "2" &&
      Number(values.Step1.Accompagnant) === 0
    ) {
      prix_chambre = values.Step3.selectedRoom.price.price_two_curists;
    } else if (
      values.Step1.NbCuriste === "1" &&
      Number(values.Step1.Accompagnant) === 1
    ) {
      prix_chambre = values.Step3.selectedRoom.price.price_curist_companion;
    } else if (
      values.Step1.NbCuriste === "1" &&
      Number(values.Step1.Accompagnant) === 0
    ) {
      prix_chambre = values.Step3.selectedRoom.price.prices_single;
    }
  }

  let NbCuriste = values.Step1.NbCuriste;
  if (values.Step2.Value === "2" || values.Step2.Value === "1") {
    NbCuriste = 1;
  }

  let Total =
    (values.CurePrice * (1 - (values.Discount / 100))) * NbCuriste * values.nbNight +
    values.Step2.Price * values.nbNight +
    Number(sumPrice(prix_chambre)) +
    Number(values.AdditionalProductsPrice);

  return Total.toFixed(2).replace(".", ",");
};

export const getCategoryTitle = (categoryArray: any[]) => {
  const categoryTitle = categoryArray.map((p) => p.title);
  return categoryTitle;
};
