import { Button, Center, Group, Title, Text, Flex, Space } from "@mantine/core";
import { ValuesContext } from "../../ValuesContext";
import { useContext } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import { sumPrice } from "./utils";

const Step5 = (props: {
  active: number;
  night: number;
  handleStepChange(nextStep: number): any;
}) => {
  const { values } = useContext(ValuesContext);

  const getAdditionalProductPrice = (chargingMode: string, price: number) => {
    let finalprice = 0;

    switch (chargingMode) {
      case "PerPersonPerTimeUnit":
        finalprice =
          price *
          (values.Step1.NbCuriste !== "1" || values.Step1.Accompagnant === 1
            ? 2
            : 1) *
          props.night;
        break;
      case "PerTimeUnit":
        finalprice = price * props.night;
        break;
      case "PerPerson":
        finalprice =
          price *
          (values.Step1.NbCuriste !== "1" || values.Step1.Accompagnant === 1
            ? 2
            : 1);
        break;
      case "Once":
        finalprice = price;
        break;
      default:
        break;
    }

    return finalprice;
  };

  useDocumentTitle(`Recapitulatif de la réservation - ${values.Hotel.name}`);
  return (
    <div className="select-zone">
      <Center>
        <Title order={3}>Récapitulatif</Title>
        <Space h="xl" />
        <Space h="xl" />
      </Center>
      <Title order={3}>Participants</Title>
      <Text className={"main-detail"}>{values.Step1.Label}</Text>
      <Text>{values.Step1.Description}</Text>
      <Title order={3}>Programmes des soins</Title>
      <Flex justify={"space-between"}>
        <Text className={"main-detail"}>Curiste 1 : {values.CureName}</Text>
      </Flex>
      {Number(values.Step2.Value) > 0 && (
        <Flex justify={"space-between"}>
          <Text className={"main-detail"}>
            Curiste 2 : {values.Step2.CureName}
          </Text>
        </Flex>
      )}

      {values.Hotel.id !== 1 && (
        <>
          <Title order={3}>Votre Chambre</Title>
          <Flex justify={"space-between"}>
            <Text className={"main-detail"}>{values.Step3.RoomName}</Text>
          </Flex>
          <Title order={3}>Restauration</Title>
          <Flex justify={"space-between"}>
            <Text className={"main-detail"}>{values.Restauration.ProductName}</Text>
          </Flex>

          <Title order={3}>Options</Title>
          {values.AdditionalProducts.map((product) => (
            <Flex justify={"space-between"}>
              <Text className={"main-detail"}>{product.Count}   {product.ProductName}</Text>
              <Text className={"main-detail"}>{(getAdditionalProductPrice(product.chargingMode, product.price) * product.Count).toFixed(2).replace(".", ",")}€</Text>
            </Flex>
          ))}

        </>
      )}

      <Space h="xl" />
      <Center>
        30% d’arrhes vous sera demandé pour confirmer votre séjour sous 24 heures.
      </Center>
      <Center>Sans cela, la réservation sera automatiquement annulée.</Center>


      <Group position="center" my="xl">

        <Button
          className={"step-btn-previous"}
          onClick={() => {
            if (values.Hotel.id === 1) {
              props.handleStepChange(props.active - 3);
            } else {
              props.handleStepChange(props.active - 1);
            }
          }}

        >
          ← Retour
        </Button>
        <Button
          className={"night-nav-active"}
          onClick={() => props.handleStepChange(props.active + 1)}
        >
          Suivant →
        </Button>
      </Group>
    </div>
  );
};
export default Step5;
