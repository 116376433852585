import { useState } from "react";
import hotelDataProvider from "../DataProvider/hotelDataProvider";

export function useCureSelection() {
  const [opened, setOpened] = useState(false);
  const [cureSelect, setCureSelect] = useState(false);
  const [selectedCure, setSelectedCure] = useState<any>({
    id: 0,
    TitleService: "",
    TitleSoins: "",
    Tag: "",
    NbNight: 0,
    NbHeal: 0,
    Resume: "",
    Cure: [],
    MinPriceBedroom: [],
    Mews_id: "",
    Hotel: {},
  });

  const handleDrawer = async (items: any, open: boolean) => {
    if (open) {
      setOpened(true);
    }

    const hotel = await hotelDataProvider.getHotelId(items.hotel_id);
    setSelectedCure({
      id: items.id,
      TitleService: items.title,
      TitleSoins: "6 Soins",
      Tag: items.category_title,
      NbNight: items.nights,
      NbHeal: items.category_title[0],
      Resume: items.description,
      Cure: items.description,
      MinPriceBedroom: items.MinPriceBedroom,
      Mews_id: items.mews_id,
      Hotel: hotel,
    });
  };

  const drawerCureSelect = () => {
    setCureSelect(true);
    setOpened(false);
  };

  const SelectCureOnClick = (items: any) => {
    setCureSelect(true);
    setSelectedCure({
      id: items.id,
      TitleService: items.title,
      TitleSoins: "6 Soins",
      Tag: items.category_title,
      NbNight: items.nights,
      NbHeal: items.category_title[0],
      Resume: items.description,
      Cure: items.description,
      MinPriceBedroom: items.MinPriceBedroom,
      Mews_id: items.mews_id,
      Hotel: {},
    });
    setOpened(false);
  };

  // Ajoutez ici les autres variables d'état et fonctions
  return {
    selectedCure,
    setSelectedCure,
    opened,
    setOpened,
    cureSelect,
    setCureSelect,
    handleDrawer,
    drawerCureSelect,
    SelectCureOnClick,
    // Inclure les autres variables d'état et fonctions
  };
}
