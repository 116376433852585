import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import "./fonts.css";
import Home from "./Home";
import Cart from "./Cart";
import Menu from "./Menu";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  { path: "cart", element: <Cart /> },
]);
const App = () => {
  return (
    <React.StrictMode>
      <Menu />
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};
export default App;
