const MEWS_API_BASE_URL = process.env.REACT_APP_MEWS_API_BASE_URL;
const MEWS_CLIENT = process.env.REACT_APP_MEWS_CLIENT;

const getAllResources = async (
  clientToken: string,
  accessToken: string,
  client: string,
  extent: any
) => {
  const response = await fetch(`${MEWS_API_BASE_URL}/resources/getAll`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ClientToken: clientToken,
      AccessToken: accessToken,
      Client: client,
      Extent: extent,
    }),
  });

  return response.json();
};

const getAvailabilityRessource = async (
  clientToken: string,
  accessToken: string,
  client: string,
  serviceId: string,
  startUTC: string,
  endUTC: string
) => {
  const response = await fetch(
    `${MEWS_API_BASE_URL}/services/getAvailability`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ClientToken: clientToken,
        AccessToken: accessToken,
        Client: client,
        ServiceId: serviceId,
        FirstTimeUnitStartUtc: startUTC,
        LastTimeUnitStartUtc: endUTC,
      }),
    }
  );

  return response.json();
};

const getImageUrls = async (
  clientToken: string,
  accessToken: string,
  client: string,
  images: any
) => {
  const response = await fetch(`${MEWS_API_BASE_URL}/images/getUrls`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ClientToken: clientToken,
      AccessToken: accessToken,
      Client: client,
      Images: images,
    }),
  });

  return response.json();
};

const roomCategoryDataProvider = {
  getList: async (resource: string, constants: any) => {
    if (resource === "resources") {
      const extent = {
        Resources: false,
        ResourceCategories: true,
        ResourceCategoryAssignments: false,
        ResourceCategoryImageAssignments: true,
        ResourceFeatures: false,
        ResourceFeatureAssignments: false,
        Inactive: false,
      };

      if (constants.client_token && constants.access_token && MEWS_CLIENT) {
        return getAllResources(
          constants.client_token,
          constants.access_token,
          MEWS_CLIENT,
          extent
        );
      } else {
        return;
      }
    }

    throw new Error(`Unsupported resource: ${resource}`);
  },

  getImage: async (imageId: string, constants: any) => {
    const image = [
      {
        ImageId: imageId,
        Width: 100,
        Height: 100,
        ResizeMode: "Fit",
      },
    ];

    if (constants.client_token && constants.access_token && MEWS_CLIENT) {
      return getImageUrls(
        constants.client_token,
        constants.access_token,
        MEWS_CLIENT,
        image
      );
    } else {
      return;
    }
  },

  getAllAvailability: async (
    resource: string,
    serviceId: string,
    startUTC: string,
    endUTC: string,
    constants: any
  ) => {
    if (resource !== "resources") {
      throw new Error(`Unsupported resource: ${resource}`);
    }

    const start = new Date(startUTC);
    start.setHours(24, 0, 0, 0);
    const startDate = start.toISOString();

    const end = new Date(endUTC);
    end.setHours(24, 0, 0, 0);
    const endDate = end.toISOString();

    if (!constants.client_token || !constants.access_token || !MEWS_CLIENT) {
      return [];
    }

    const CategoryAvailabilities = await getAvailabilityRessource(
      constants.client_token,
      constants.access_token,
      MEWS_CLIENT,
      serviceId,
      startDate,
      endDate
    );

    const allcategorie = await roomCategoryDataProvider.getList(
      "resources",
      constants
    );

    const filtered = allcategorie.ResourceCategories.filter(
      (resource: any) => resource.ServiceId === serviceId
    );

    let malist: any[] = [];

    filtered.map((filter: { Id: any }) => malist.push(filter.Id));

    const filtered2 = CategoryAvailabilities.CategoryAvailabilities.filter(
      (resource: any) => malist.includes(resource.CategoryId)
    );

    return filtered2;
  },

  // retourne les chambres disponible et le lien de leur image
  getAvailability: async (
    resource: string,
    serviceId: string,
    startUTC: string,
    endUTC: string,
    constants: any
  ) => {
    if (resource !== "resources") {
      throw new Error(`Unsupported resource: ${resource}`);
    }

    const start = new Date(startUTC);
    start.setHours(24, 0, 0, 0);
    const startDate = start.toISOString();

    const end = new Date(endUTC);
    end.setDate(end.getDate() - 1);
    end.setHours(24, 0, 0, 0);
    const endDate = end.toISOString();

    if (!constants.client_token || !constants.access_token || !MEWS_CLIENT) {
      return [];
    }

    const CategoryAvailabilities = await getAvailabilityRessource(
      constants.client_token,
      constants.access_token,
      MEWS_CLIENT,
      serviceId,
      startDate,
      endDate
    );

    const categoriesfilter =
      CategoryAvailabilities.CategoryAvailabilities.filter(
        (categorie: any) => !categorie.Availabilities.includes(0)
      ).map((categorie: any) => categorie.CategoryId);

    const allcategorie = await roomCategoryDataProvider.getList(
      "resources",
      constants
    );

    // Recupere que les categories de chambres qui nous interesse
    const filtered = allcategorie.ResourceCategories.filter(
      (resource: any) =>
        resource.ServiceId === serviceId &&
        categoriesfilter.includes(resource.Id)
    );

    // recupere les premieres images des categories de chambres
    const imageids = allcategorie.ResourceCategoryImageAssignments;
    const categoryIdSet = new Set();
    const uniqueImageIds = [];

    for (const resource of imageids) {
      if (!categoryIdSet.has(resource.CategoryId)) {
        // Si CategoryId n'est pas déjà dans l'ensemble, l'ajoute à la liste des uniques
        categoryIdSet.add(resource.CategoryId);
        uniqueImageIds.push(resource);
      }
    }

    const urls = await roomCategoryDataProvider.getImg(
      "images",
      uniqueImageIds,
      constants
    );

    const roomData = filtered.map((room: any, index: number) => ({
      ...room,
      imageUrl: urls.ImageUrls[index].Url,
    }));

    return roomData;
  },

  getImg: async (image: string, myimages: any, constants: any) => {
    if (image === "images") {
      const images = myimages.map((image: any) => ({
        ImageId: image.ImageId,
        Width: 400,
        Height: 270,
        ResizeMode: "Fit",
      }));

      if (constants.client_token && constants.access_token && MEWS_CLIENT) {
        return getImageUrls(
          constants.client_token,
          constants.access_token,
          MEWS_CLIENT,
          images
        );
      } else {
        return;
      }
    }

    throw new Error(`Unsupported resource: ${image}`);
  },
};

export default roomCategoryDataProvider;
