import { Burger, Container, Flex, Image, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import btrLogo from "./images/logo.jpg";
import { useState } from "react";

const Menu = () => {
  const [burgerOpened, { toggle }] = useDisclosure(false);
  return (
    <>
      <Container size="xl" p="xl">
        <Flex justify={"space-between"} align="center">
          <Burger
            aria-label="Ouvrir la navigation"
            title="Ouvrir la navigation"
            color="var(--secondary-color);"
            size={30}
            opened={burgerOpened}
            onClick={toggle}
          />
          <a href="/"><Image
            className="image-menu"
            src={
              btrLogo
            }
          /></a>


          <Text sx={{ color: "var(--secondary-color)" }}>FR</Text>
        </Flex>
      </Container>
    </>
  );
};
export default Menu;
