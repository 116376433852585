// constants.ts
interface Constants {
    [key: number]: {
        Service_Id: string;
        petit_dejeuner_id: string;
        rate_id: string;
        categorie_id: string;
        client_token: string;
        access_token: string;
        AgeCategoryId: string;
    };
}

const constants: Constants = {
    1: {
        Service_Id: "f19b8604-fbc9-49a0-9447-adb300e3c258",
        petit_dejeuner_id: "1fb3dd20-43b7-4718-a9aa-adb4007a5d2c",
        rate_id: "2b3e818c-2bf1-45c2-a590-adb300e3c2ef",
        categorie_id: "c922d04b-e790-4ea1-9005-adb300e3c573",
        client_token: "FF2AD82AA1204B8DABFDB000008BC441-FB6093740CC7693AFAE5D28EF7A4B4F",
        access_token: "C6A4E5CE618E48649389B000008D1B3F-FEC6722E2E44855FC4E8FEF7B988193",
        AgeCategoryId: "189e85ef-58ba-4605-be62-ade400cf7cfe",
    },
    2: {
        Service_Id: "487dd1b4-e6be-4819-a660-ad2f00498d7b",
        petit_dejeuner_id: "d5102f95-3272-4e4a-a73b-ad740102336f",
        rate_id: "e7ed64d4-982d-4fc6-853e-ad7400aba695",
        categorie_id: "1055d3d3-6b62-4dd7-bc5d-ad7400a43e76",
        client_token: "FF2AD82AA1204B8DABFDB000008BC441-FB6093740CC7693AFAE5D28EF7A4B4F",
        access_token: "367FC13FB39744AB98F3B04400AD6134-4611B83E66FD4A23086D52DB7A0E21A",
        AgeCategoryId: "bf1a1b30-1635-4aa6-a715-ade400cf7547",
    },
    3: {
        Service_Id: "e9854ba7-c17d-4791-ab80-adaa00a3ccb8",
        petit_dejeuner_id: "ab62d7bc-c2e0-4d1d-90f3-adad00973b4a",
        rate_id: "2589967b-ba00-42a0-9cf4-adaa00a3cd58",
        categorie_id: "c1b5c862-9c1f-4b7d-af65-adaa00f3d9cc",
        client_token: "FF2AD82AA1204B8DABFDB000008BC441-FB6093740CC7693AFAE5D28EF7A4B4F",
        access_token: "1DACBE3C4A73488DAF99B04400AD949F-1698B12C6A4F70CC6358E6E66BF3AD1",
        AgeCategoryId: "3871377f-108b-4669-9d68-ade400cf7c57",
    },
};

export function getConstants(destination: number) {
    return constants[destination];
}
