import { Key, useContext, useEffect, useState } from "react";
import {
  Center,
  Title,
  Text,
  Button,
  Group,
  Space,
  Image,
  Anchor,
  Grid,
} from "@mantine/core";
import { ValuesContext } from "../../ValuesContext";
import useDocumentTitle from "../../useDocumentTitle";
import { TotalPrice, sumPrice } from "./utils";
import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

const Step3 = (props: {
  idCure: number;
  active: number;
  nbNight: number;
  handleStepChange(nextStep: number): any;
  roomData: any;
}) => {
  const { values, setValues } = useContext(ValuesContext);
  const [alert, setlAlert] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<any>({
    id: values.Step3.RequestedCategoryId,
    room: ''
  });


  // mettre a jour le prix
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      TotalPrice: TotalPrice(values),
    }));
  }, [setValues, values]);



  const updateStep3Values = async (
    selectedRoom: any
  ) => {
    let newStep3Values: {
      RequestedCategoryId: string;
      RoomName: string;
      Price: any[];
      selectedRoom: {}
    };

    let price: any[] = [];

    if (Number(values.Step1.NbCuriste) === 2 && Number(values.Step1.Accompagnant) === 0) {
      price = selectedRoom.price.price_two_curists
    } else if (Number(values.Step1.NbCuriste) === 1 && Number(values.Step1.Accompagnant) === 1) {
      price = selectedRoom.price.price_curist_companion
    } else if (Number(values.Step1.NbCuriste) === 1 && Number(values.Step1.Accompagnant) === 0) {
      price = selectedRoom.price.prices_single
    }

    newStep3Values = {
      RequestedCategoryId: selectedRoom.Id,
      RoomName: selectedRoom.Names["fr-FR"],
      Price: price,
      selectedRoom: selectedRoom
    }

    await setValues((prevValues) => ({
      ...prevValues,
      Step3: newStep3Values,
    }));

  };

  // Fonction de tri par prix croissant
  const sortByPrice = (data: any[]) => {
    return data.sort((a: any, b: any) => {
      const priceA = getPrice(a);
      const priceB = getPrice(b);

      return priceA - priceB;
    });
  };

  // Fonction utilitaire pour obtenir le prix en fonction des conditions spécifiques
  const getPrice = (room: { price: any; }) => {
    const { price } = room;

    if (Number(values.Step1.NbCuriste) === 2) {
      return sumPrice(price.price_two_curists) / price.price_two_curists.length;
    } else if (Number(values.Step1.NbCuriste) === 1 && Number(values.Step1.Accompagnant) === 1) {
      return sumPrice(price.price_curist_companion) / price.price_curist_companion.length;
    } else if (Number(values.Step1.NbCuriste) === 1) {
      return sumPrice(price.prices_single) / price.prices_single.length;
    }

    return 0;
  };

  // Tri du tableau roomData par prix croissant
  const sortedRoomData = sortByPrice(props.roomData);

  useDocumentTitle(`Choix de la chambre - ${values.Hotel.name}`);
  return (
    <div className="select-zone">
      <Center>
        <Title order={3}>Choisissez votre chambre</Title>
      </Center>
      <Space h="xl" />


      {props.roomData.length === 0 ? (
        <Center>
          <Text fz="xl" my="xl" align="center">Aucune chambre n'est disponible durant cette période.</Text>
        </Center>
      ) : (
        <Grid mx={12} justify="center" sx={() => ({ textAlign: "center" })}>
          {sortedRoomData.map((room: any, key: Key | null | undefined) => (
            <Grid.Col md={6} key={key}>
              <Image height={160} fit="cover" src={room.imageUrl} />
              <Title order={4}>{room.Names["fr-FR"]}</Title>
              <Text size="xs">{room.Descriptions["fr-FR"]}</Text>
              {Number(values.Step1.NbCuriste) === 2 ? (
                <Text className="main-detail">{(sumPrice(room.price.price_two_curists) / room.price.price_two_curists.length).toFixed(2).replace(".", ",")}€ / nuit</Text>
              ) : (Number(values.Step1.NbCuriste) === 1 && Number(values.Step1.Accompagnant) === 1 ? (
                <Text className="main-detail">{(sumPrice(room.price.price_curist_companion) / room.price.price_curist_companion.length).toFixed(2).replace(".", ",")}€ / nuit</Text>
              ) : Number(values.Step1.NbCuriste) === 1 ? (
                <Text className="main-detail">{(sumPrice(room.price.prices_single) / room.price.prices_single.length).toFixed(2).replace(".", ",")}€ / nuit</Text>
              ) : null)}
              <Space h="xl" />
              <Button
                className={selectedRoom?.id === room.Id ? "night-nav-active" : "night-nav"}
                onClick={() => {
                  setSelectedRoom({
                    id: room.Id,
                    room: room
                  });
                  updateStep3Values(room)
                }}
              >
                {selectedRoom?.id === room.Id ? "Sélectionné" : "Choisir"}
              </Button>

              <Space h="xl" />
              <Space h="xl" />
            </Grid.Col>
          ))}
        </Grid>

      )}


      {props.roomData.length > 0 ? (
        <>
          {alert && (
            <Alert icon={<IconAlertCircle size="1rem" />} title="Erreur !" color="red" mb={20}>
              Veuillez choisir une chambre.
            </Alert>
          )}
          <Group position="center" my="xl">

            <Button
              className={"step-btn-previous"}
              onClick={() => props.handleStepChange(props.active - 1)}
            >
              ← Retour
            </Button>

            <Button
              className={"night-nav-active"}
              onClick={() => {
                if (selectedRoom.id) {
                  updateStep3Values(selectedRoom);
                  props.handleStepChange(props.active + 1);
                } else {
                  setlAlert(true)
                }
              }}
            >
              Suivant →
            </Button>
          </Group>
        </>
      ) : (

        <Group position="center">

          <Anchor href="/" my="xl" className={"step-btn-next"}>
            Changer les dates
          </Anchor>


        </Group>
      )
      }

    </div>
  );
};
export default Step3;
