import {
  Badge,
  Button,
  Card,
  Center,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Text,
  Space,
  ScrollArea,
  Image,
  Modal,
} from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCureSelection } from "../../hooks/useCureSelection";
import { faBed, faUtensils, faSpa, faAward, faHeadset, faShield, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import DrawerContent from "./drawerContent/DrawerContent";
import { useDisclosure } from '@mantine/hooks';
import DateSelectionCard from "./cureCard/CardDate";
interface CureSectionProps {
  /*
  data: any;
  priceBedroom: any;
  availability: any;
  roomCategoryPrice: number;
  allProduct: any;
  Petitdej: Number;
  showModal: boolean;
  */
  hotels: any;
}

const CureSection = (props: CureSectionProps) => {
  const {
    opened,
    setOpened,
    selectedCure,
    cureSelect,
    handleDrawer,
    drawerCureSelect,
    SelectCureOnClick,
    // ... Les autres variables d'état et fonctions ...
  } = useCureSelection();
  const [dateSelect, setDateSelect] = useState<any>({
    id: 0,
    start_date: "",
    end_date: "",
    price: 0,
    isSelect: false,
  });

  const [modalopen, { open, close }] = useDisclosure(false);
  const [isMobile, setIsMobile] = useState(false);
  const [currentHotel, setCurrentHotel] = useState<any>();
  const handleDateSelected = (key: any, start_date: string, price: number) => {
    if (dateSelect.id === key && dateSelect.isSelect) {
      setDateSelect({
        id: "",
        start_date: "",
        end_date: "",
        price: "",
        isSelect: false,
      });
    } else {
      const end_date = dayjs(start_date)
        .add(selectedCure.NbNight, "day")
        .toISOString();
      setDateSelect({
        id: key,
        start_date: start_date,
        end_date: end_date,
        price: price,
        isSelect: true,
      });
    }
  };

  const getMinPriceCure = (cure: any, hotel: any) => {
    return cure.MinPriceBedroom && (Number(cure.minPrice) * Number(cure.nights) + (Number(Math.min(...cure.MinPriceBedroom)) * Number(cure.nights)) + ((Number(hotel.Petitdej) * Number(cure.nights))))
  };

  const navigate = useNavigate();
  const handleOnClick = () => {

    let { id, TitleService, TitleSoins, NbNight, Cure, Mews_id, Hotel } = selectedCure;

    const { start_date, end_date } = dateSelect;
    const priceCure = currentHotel.Products.find((product: any) => product.Id === selectedCure.Mews_id);
    navigate("/cart", {
      state: {
        id: id,
        name: TitleService,
        titleSoins: TitleSoins,
        date: [start_date, end_date],
        nbNight: NbNight,
        cure: Cure,
        price: priceCure.Pricing.Value.GrossValue,
        mews_id: Mews_id,
        Hotel: Hotel,
        Hotel_id: Hotel.id
      },
    });


  };


  const screenHeight = window.innerHeight;

  let sortedStays: any[] = [];

  const allStays = props.hotels.flatMap((hotel: any) => hotel.Data);

  const staysWithHotelInfo = allStays.map((stay: any) => {
    const hotelInfo = props.hotels.find((hotel: any) =>
      hotel.Data.some((hotelStay: any) => hotelStay.id === stay.id)
    );

    // Inclure toutes les propriétés sauf 'Data' dans hotelInfoWithoutData
    const { Data, ...hotelInfoWithoutData } = hotelInfo;

    const resul = {
      ...stay,
      hotelInfo: hotelInfoWithoutData,
    };

    return resul;
  });

  sortedStays = staysWithHotelInfo.sort((a: any, b: any) => a.nights - b.nights); // Tri par ordre décroissant de nuits


  const location = useLocation();

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const stay = params.get('stay_id');
    const Numberstay = Number(stay);

    if (Numberstay) {
      const selectedCure = sortedStays.find(item => item.id === Numberstay);

      SelectCureOnClick(selectedCure)
      setCurrentHotel(selectedCure.hotelInfo)
      handleDrawer(selectedCure, false)
      open();
      if (window.innerWidth <= 1199) {
        setIsMobile(true);
      }
    }

  }, []);

  // Sélectionnez l'élément selectionné
  const targetElement = document.querySelector('.mantine-1rc8zwf');

  // scroll jusqu'a l'element
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: 'auto',
      block: 'center',
    });
  }


  return (
    <>
      {sortedStays.length === 0 ? (
        <>
          <Grid align="flex-start">
            <Grid.Col md={12} sm={12} lg={8}>
              <h4 className={"main-text text-center"}>Aucun résultat</h4>
            </Grid.Col>
          </Grid>
        </>
      ) : (
        <>
          {isMobile && !opened && (
            <Modal opened={modalopen} onClose={close} fullScreen>
              <DateSelectionCard
                currentHotel={currentHotel}
                selectedCure={selectedCure}
                dateSelect={dateSelect}
                handleDateSelected={handleDateSelected}
                handleOnClick={handleOnClick}
                isMobile={isMobile}
              />
            </Modal>
          )}

          <Drawer
            opened={opened}
            onClose={() => setOpened(false)}
            padding="xl"
            size={600}
            position="right"
          >
            <ScrollArea h={`${(screenHeight / 100) * 90}px`}  >

              <DrawerContent selectedCure={selectedCure} />
              <Center>
                <Button
                  className={"night-nav-active"}
                  uppercase
                  mt={"xl"}
                  onClick={() => {
                    drawerCureSelect()
                    handleDrawer(selectedCure, false)
                  }
                  }
                >
                  Choisir
                </Button>
              </Center>
            </ScrollArea>
          </Drawer>
          <Grid align="flex-start">
            <Grid.Col md={12} sm={12} lg={8}>
              {sortedStays.map((stay: any, index: number) => (
                <Card
                  className={"curesection-block"}
                  key={index}
                  sx={(theme) => ({
                    backgroundColor:
                      cureSelect === true && selectedCure.id === stay.id
                        ? theme.colorScheme === "dark"
                          ? theme.colors.dark[5]
                          : theme.colors.gray[1]
                        : theme.colors.white,
                  })}
                >

                  <Grid>
                    <Grid.Col md={12} lg={12}>
                      <Card.Section>
                        <Group>
                          <Text className={"main-detail"}>
                            <FontAwesomeIcon icon={faLocationDot} /> {stay.hotelInfo.name}
                          </Text>
                          <Divider orientation="vertical" />
                          <Text className={"main-detail"}>
                            {stay.nights === 1 ? (
                              <>
                                <FontAwesomeIcon icon={faBed} /> {stay.nights} Nuit
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon icon={faBed} /> {stay.nights} Nuits
                              </>
                            )}

                          </Text>
                          <Divider orientation="vertical" />
                          <Text className={"main-detail"}>
                            <FontAwesomeIcon icon={faUtensils} /> Petit-déjeuner
                          </Text>
                          <Divider orientation="vertical" />
                          <Text className={"main-detail"}>
                            <FontAwesomeIcon icon={faSpa} /> {stay.category_title[0]}
                          </Text>
                        </Group>
                      </Card.Section>
                    </Grid.Col>

                    <Grid.Col md={3} >
                      <Space h="lg" />
                      <Image maw={240} mx="auto" radius="md" src={stay.image} alt="Cure" />

                    </Grid.Col>
                    <Grid.Col md={9}>
                      <div>
                        <Text>
                          <h2 className={"main-title"}>{stay.title}</h2>
                        </Text>
                        <Badge style={{ marginLeft: '10px' }} variant="filled">{stay.category_title[0]}</Badge>
                        <Space h="md" />
                        <Text> <div dangerouslySetInnerHTML={{ __html: stay.description.split('</p>')[0] + '</p>' }}></div></Text>
                        <Space h="md" />
                        <Text
                          className={"main-text"}
                          underline
                          onClick={() => {
                            handleDrawer(stay, true)
                            setCurrentHotel(stay.hotelInfo)
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Voir plus de détails
                        </Text>
                      </div>
                    </Grid.Col>

                    <Grid.Col md={12} lg={12}>
                      <Card.Section>
                        <Flex align="flex-end" direction="column">
                          <Text className={"from-price"}>A partir de</Text>
                          <Flex align="center" direction="row" gap="xs">
                            <Text className={"price"}>{getMinPriceCure(stay, stay.hotelInfo)}€</Text>
                            <Text className={"from-price"}>/personnes</Text>
                          </Flex>
                          <Space h="md" />
                          {cureSelect === true && selectedCure.id === stay.id ? (
                            <Button className={"night-nav-active"} uppercase
                              onClick={() => {
                                SelectCureOnClick(stay)
                                setCurrentHotel(stay.hotelInfo)
                                handleDrawer(stay.hotelInfo, false)
                                open();
                                if (window.innerWidth <= 1199) {
                                  setIsMobile(true);
                                }
                              }}
                            >
                              Sélectionner une date →
                            </Button>
                          ) : (
                            <Button
                              className={"night-nav-active"}
                              uppercase
                              onClick={() => {
                                SelectCureOnClick(stay)
                                setCurrentHotel(stay.hotelInfo)
                                handleDrawer(stay, false)
                                open();
                                if (window.innerWidth <= 1199) {
                                  setIsMobile(true);
                                }
                              }}

                            >
                              Choisir
                            </Button>
                          )}
                        </Flex>
                      </Card.Section>
                    </Grid.Col>
                  </Grid>
                </Card>

              ))}
            </Grid.Col>
            <Grid.Col md={12} lg={4} className="card_date">

              <Card
                className={"reinsurance-block"}
                radius="md"
                withBorder
                sx={{
                  backgroundColor: "white",
                  borderColor: "var(--secondary-color)",
                  color: "var(--secondary-color)",
                }}
              >
                <Flex direction="row" align="center">
                  <FontAwesomeIcon icon={faAward} size="lg" />
                  <Space w="xs" />
                  <Text>Meilleur tarif garanti</Text>
                </Flex>
                <Space h="md" />
                <Flex direction="row" align="center">
                  <FontAwesomeIcon icon={faHeadset} size="lg" />
                  <Space w="xs" />
                  <Text>
                    Conseils et devis personnalisés
                    <br />
                    au 05 59 52 75 85
                  </Text>
                </Flex>
                <Space h="lg" />
                <Flex direction="row" align="center">
                  <FontAwesomeIcon icon={faShield} size="lg" />
                  <Space w="xs" />
                  <Text>Paiement et données sécurisés</Text>
                </Flex>
              </Card>


              {cureSelect && !isMobile && !opened && (
                <Card py="0" mx="lg" withBorder>
                  <DateSelectionCard
                    currentHotel={currentHotel}
                    selectedCure={selectedCure}
                    dateSelect={dateSelect}
                    handleDateSelected={handleDateSelected}
                    handleOnClick={handleOnClick}
                    isMobile={isMobile}
                  />

                </Card>
              )}

            </Grid.Col>
          </Grid >
        </>
      )}
    </>
  );
};

export default CureSection;
