import React from "react";
import { Card, Text, Space, Center, Button } from "@mantine/core";
import DateCarousel from "./DateCarousel";

const DateSelectionCard = (props: {
    currentHotel: any
    selectedCure: any,
    dateSelect: any,
    handleDateSelected: any,
    handleOnClick: any,
    isMobile: boolean
}) => {
    console.log(props.currentHotel.id)
    return (
        <>
            <Card.Section className={"top-card-title"}>
                <Text align="center">Sélectionnez votre date de départ</Text>
            </Card.Section>
            <DateCarousel
                priceBedroom={props.selectedCure.MinPriceBedroom}
                availability={props.currentHotel.Avaibility}
                selectedCure={props.selectedCure}
                dateSelect={props.dateSelect}
                handleDateSelected={props.handleDateSelected}
                allProduct={props.currentHotel.Products}
                nbNight={props.selectedCure.NbNight}
                Petitdej={props.currentHotel.Petitdej}
            />

            {props.dateSelect.isSelect && (
                <div style={props.isMobile ? { position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: "white" } : {}}>
                    <Space h="xl" />
                    <Center>
                        <Button
                            className={"night-nav-active"}
                            uppercase
                            onClick={() => {
                                if (props.dateSelect.isSelect) {
                                    props.handleOnClick();
                                } else {
                                    alert("Veuillez choisir une date");
                                }
                            }}
                        >
                            Continuer
                        </Button>
                    </Center>
                    <Space h="xl" />
                </div>
            )}

        </>

    );
};

export default DateSelectionCard;
