import { Button, Center, Container, Group, Radio, Space, Title } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { ValuesContext } from "../../ValuesContext";
import useDocumentTitle from "../../useDocumentTitle";
import React from "react";
import { TotalPrice } from "./utils";
import { Alert } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

const step1ValuesObject = {
  "1": {
    NbCuriste: "1",
    Accompagnant: 0,
    Label: "1 curiste",
    Description:
      "Vous venez profiter de votre programme de soin seul(e) avec un accès à l’hôtel et à la restauration selon la formule de pension de votre choix."
  },
  "2": {
    NbCuriste: "2",
    Accompagnant: 0,
    Label: "2 curistes",
    Description:
      "Les deux participants profitent d'un programme de soins et bénéficient d'un accès à l'hôtel et à la restauration selon la formule de pension de leur choix."
  },
  "3": {
    NbCuriste: "1",
    Accompagnant: 1,
    Label: "1 curiste et 1 accompagnant",
    Description:
      "Seul un participant profite du programme de soins. Les deux participants bénéficient d'un accès à l'hôtel et à la restauration selon la formule choisie."
  },
};

const Step1 = (props: {
  active: number;
  initialValues: any
  handleStepChange(number: number): any;
  mydiscount: any;
  discountmews: any
}) => {
  const { values, setValues } = useContext(ValuesContext);
  const [participantValue, setParticipantValue] = useState(values.Step1.NbCuriste);
  const [alert, setlAlert] = useState(false);

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      Discount: props.mydiscount,
      discountmews: props.discountmews,
      TotalPrice: TotalPrice(values),
    }));
  }, [setValues, values]);


  const updateStep1Values = async (value: string) => {
    const newStep1Values = step1ValuesObject[value as keyof typeof step1ValuesObject] || {
      NbCuriste: 0,
      Accompagnant: 0,
      Label: "",
      Description: ""
    };


    await setValues(
      props.initialValues
    );
    await setValues((prevValues) => ({ ...prevValues, Step1: newStep1Values }));

    await setParticipantValue(value);

    props.handleStepChange(9);

  };

  useDocumentTitle(`Choix du nombre de participants - ${values.Hotel.name}`);

  return (
    <div className="select-zone">
      <Container fluid>
        <Center>
          <Title order={3}>Participants</Title>
        </Center>
        <Radio.Group
          name="Person"
          orientation="vertical"
          spacing="xl"
          offset="xl"
          value={participantValue}
          onChange={updateStep1Values}
        >
          {Object.entries(step1ValuesObject).map(([key, value]) => (
            <React.Fragment key={key}>
              <Radio
                value={key}
                label={value.Label}
                description={value.Description}
              />
              <Space h="xs" />
            </React.Fragment>
          ))}
        </Radio.Group>
      </Container>
      {alert && (
        <Alert icon={<IconAlertCircle size="1rem" />} title="Erreur !" color="red" mb={20}>
          Veuillez choisir le nombre de participants.
        </Alert>
      )}
      <Group position="center" my="xl">
        <Button
          className={"night-nav-active"}
          onClick={() => {
            if (participantValue) {
              props.handleStepChange(props.active + 1);
            } else {
              setlAlert(true)
            }
          }}
        >
          Suivant →
        </Button>
      </Group>

    </div>
  );
};

export default Step1;
