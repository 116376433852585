const MEWS_API_BASE_URL = process.env.REACT_APP_MEWS_API_BASE_URL;
const MEWS_CLIENT = process.env.REACT_APP_MEWS_CLIENT;
const token = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const getProductAll = async (
  clientToken: string,
  accessToken: string,
  client: string,
  serviceIds: string[]
) => {
  try {
    const response = await fetch(`${MEWS_API_BASE_URL}/products/getAll`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ClientToken: clientToken,
        AccessToken: accessToken,
        Client: client,
        ServiceIds: serviceIds,
      }),
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
};

const getProductsAllBL = async () => {
  const response = await fetch(`${API_URL}/stay_option`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const productDataProvider = {
  getProduct: async (product: string, Service_Id: string, constants: any) => {
    if (product === "product") {
      const serviceIds = [Service_Id];

      if (constants.client_token && constants.access_token && MEWS_CLIENT) {
        return getProductAll(
          constants.client_token,
          constants.access_token,
          MEWS_CLIENT,
          serviceIds
        );
      } else {
        return;
      }
    }
    throw new Error(`Unsupported resource: ${product}`);
  },
  getAllProductsBL: async (roomPrice: string) => {
    if (roomPrice === "allProduct") {
      return getProductsAllBL();
    }

    throw new Error(`Unsupported resource: ${roomPrice}`);
  },
};

export default productDataProvider;
