import { faBed, faUtensils, faSpa } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Divider,
  Group,
  Space,
  Text,
} from "@mantine/core";

const DrawerContent = (props: { selectedCure: any }) => {

  return (
    <>
      <Badge variant="filled">{props.selectedCure.Tag}</Badge>
      <Space h="md" />
      <Text className={"main-title-items"}>
        {props.selectedCure.TitleService}
      </Text>
      <Space h="md" />
      <Group>
        <Text className={"main-detail"}>
          {props.selectedCure.nbNight === 1 ? (
            <>
              <FontAwesomeIcon icon={faBed} /> {props.selectedCure.NbNight} Nuit
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faBed} /> {props.selectedCure.NbNight} Nuits
            </>
          )}

        </Text>
        <Divider orientation="vertical" />



        <Text className={"main-detail"}>
          <FontAwesomeIcon icon={faUtensils} /> Petit-déjeuner
        </Text>
        <Divider orientation="vertical" />
        <Text className={"main-detail"}>
          <FontAwesomeIcon icon={faSpa} /> {props.selectedCure.NbHeal}
        </Text>
      </Group>
      <Divider my="xs" />
      <Text>
        <h3>Détails des soins</h3>
        <div dangerouslySetInnerHTML={{ __html: props.selectedCure.Cure }}></div>


        <div dangerouslySetInnerHTML={{ __html: props.selectedCure.Hotel.description }}></div>

      </Text>


    </>



  );
};
export default DrawerContent;
