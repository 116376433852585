import React, { createContext, useState, ReactNode } from "react";

interface Values {
  CureName: string;
  Price: any;
  Description: string,
  Hotel: any,
  Discount: number;
  discountmews: any,
  Step1: {
    NbCuriste: string;
    Accompagnant: number;
    Label: string;
    Description: string;
  };
  Step2: {
    Value: string;
    CureName: string;
    Price: any;
    Mews_id: string;
  };
  Step3: {
    RequestedCategoryId: string;
    RoomName: string;
    Price: any[];
    selectedRoom: {};
  };
  Step6: {
    idCustomer: string;
    confirmationMessage: string;
  };
  Restauration: {
    ProductId: string;
    ProductName: string;
    ProductPrice: any;
    ChargingMode: string;
  };
  AdditionalProducts: any[];
  AdditionalProductsPrice: any;
  Date: {
    StartUtc: string;
    EndUtc: string;
  };
  CurePrice: any;
  TotalPrice: any;
  IdCure: number;
  Mews_id: string;
  nbNight: number;
}

interface ValuesContextType {
  values: Values;
  setValues: React.Dispatch<React.SetStateAction<Values>>;
}

export const ValuesContext = createContext<ValuesContextType>({
  values: {} as Values,
  setValues: () => { },
});

interface ValuesProviderProps {
  children: ReactNode;
  initialValues: Values;
}

export const ValuesProvider = ({
  children,
  initialValues,
}: ValuesProviderProps) => {
  const [values, setValues] = useState<Values>(initialValues);

  return (
    <ValuesContext.Provider value={{ values, setValues }}>
      {children}
    </ValuesContext.Provider>
  );
};
