import {
  Center,
  Container,
  Title,
  Button,
  Space,
  Group,
  Loader,
  TextInput,
  Checkbox,
  Anchor,
  Text
} from "@mantine/core";
import { useContext, useState } from "react";
import customerMewsDataprovider from "../../DataProvider/customerMewsDataprovider";
import { ValuesContext } from "../../ValuesContext";
import useDocumentTitle from "../../useDocumentTitle"; import { useForm } from '@mantine/form';
import { getConstants } from "../../constants";
import emailjs from 'emailjs-com';

const Step6 = (props: {
  active: number;
  handleStepChange(nextStep: number): any;
  setFinish(finish: boolean): any;
  Hotel_id: number
}) => {

  const constants = getConstants(props.Hotel_id);

  const form = useForm({
    initialValues: {
      termes: false,
      name: '',
      fisrtname: '',
      email: '',
      phone: '',
      name2: '',
      fisrtname2: '',
      email2: '',
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Le nom doit avoir plus de 2 caractères' : null),
      fisrtname: (value) => (value.length < 2 ? 'Le prénom doit avoir plus de 2 caractères' : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'E-mail incorrect'),
      phone: (value) => (/^\d{10}$/.test(value) ? null : 'Téléphone incorrect'),
      termes: (value) => (value === false ? 'Veuillez accepter les termes et conditions' : null),
    },
  });

  const [isLoading, setIsLoading] = useState(false)
  const { values, setValues } = useContext(ValuesContext);

  // Fonction lors du clique sur le bouton Confirmer
  const SubmitButton = async () => {

    setIsLoading(true)
    if (form.values.name && form.values.fisrtname && form.values.email) {
      try {
        const oneCustomer = await customerMewsDataprovider.getOneCustomer(
          form.values.email,
          constants
        );

        let firstCustomer: any

        // récupération idCustomer
        if (oneCustomer.Customers.length === 1) {
          firstCustomer = oneCustomer.Customers[0]
        } else {
          //Création du compte
          const newcustomer = await customerMewsDataprovider.createCustomer(
            form.values.fisrtname,
            form.values.name,
            form.values.email,
            form.values.phone,
            constants
          );
          firstCustomer = newcustomer
        }

        let TwoCustomer: any

        if (form.values.name2 && form.values.fisrtname2 && form.values.email2) {
          const secondCustomer = await customerMewsDataprovider.getOneCustomer(
            form.values.email2,
            constants
          );

          // récupération idCustomer
          if (secondCustomer.Customers.length === 1) {
            TwoCustomer = secondCustomer.Customers[0]
          } else {
            //Création du compte
            const Secondnewcustomer = await customerMewsDataprovider.createCustomer(
              form.values.fisrtname2,
              form.values.name2,
              form.values.email2,
              '',
              constants
            );
            TwoCustomer = Secondnewcustomer
          }
        }

        const productJsonList = [{ "ProductId": values.Restauration.ProductId }];

        if (values.Discount > 0) {
          productJsonList.push({ "ProductId": values.discountmews })
          if (values.Step1.NbCuriste == "2") {
            productJsonList.push({ "ProductId": values.discountmews })
          }
        }

        for (let i = 0; i < values.AdditionalProducts.length; i++) {
          const product = values.AdditionalProducts[i]
          const productJson = {
            "ProductId": product.ProductId,
            "Count": product.Count
          };
          productJsonList.push(productJson);
        }

        // Ajout de la cure
        if (values.Mews_id) {
          const productJson = {
            "ProductId": values.Mews_id
          };
          productJsonList.push(productJson);

          // si 2 curistes on rajoute sa cure
          if (Number(values.Step1.NbCuriste) === 2) {
            const productSecond = {
              "ProductId": values.Step2.Mews_id
            };
            productJsonList.push(productSecond);
          }
        }

        // prix des nuits de la chambre
        const prices = values.Step3.Price
        const TimeUnitPrices = prices.map((prix, key) => ({
          Index: key,
          Amount: {
            Currency: "EUR",
            GrossValue: prix,
            TaxCodes: ["FR-S"],
          },
        }));

        // Create reservation
        const resa = await customerMewsDataprovider.createReservation(
          values.Step3.RequestedCategoryId,
          constants.rate_id,
          Number(values.Step1.NbCuriste) + Number(values.Step1.Accompagnant),
          constants.Service_Id,
          values.Date.StartUtc,
          values.Date.EndUtc,
          productJsonList,
          TimeUnitPrices,
          firstCustomer,
          TwoCustomer,
          values.CureName,
          values.Step2.CureName,
          constants
        );

        if (TwoCustomer) {
          const companion = await customerMewsDataprovider.addCompanion(resa.Reservations[0].Reservation.Id, TwoCustomer.Id, constants)
        }

        let message = '';
        if (resa.Reservations[0].Reservation.Id) {
          // créer une payment request de 30% du prix total
          const payment = await customerMewsDataprovider.createPaymentRequest(
            firstCustomer.Id,
            Number(((Number(values.TotalPrice.replace(",", ".")) / 100) * 30).toFixed(2)),
            constants
          )

          if (payment.PaymentRequests) {
            message = "Merci pour votre réservation. <br> Vous allez recevoir un e-mail pour finaliser le paiement."
            props.setFinish(true)
          } else {
            message = "Erreur lors de l'envoie de la demande de paiement"
          }
        } else {
          message = "Erreur."
        }

        // Set idCustomer in values
        setValues((prevValues) => ({
          ...prevValues,
          Step6: {
            idCustomer: firstCustomer.Id,
            confirmationMessage: message
          },
        }));


      } catch (error) {
        throw new Error(`Error: ${error}`);
      }
    }
    setIsLoading(false)

  };

  const startDate = new Date(values.Date.StartUtc);
  const endDate = new Date(values.Date.EndUtc);

  const datestart = startDate.toLocaleString("fr-FR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const dateend = endDate.toLocaleString("fr-FR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const SendEmail = async () => {
    let cure = values.CureName
    if (values.Step2.CureName != '') {
      cure = cure + ' et ' + values.Step2.CureName
    }
    let data = { ...form.values, cure: cure, curiste: values.Step1.Label, arrivee: datestart, depart: dateend, total: values.TotalPrice };

    emailjs.send('booking.biarritzthalasso', 'template_rudmxni', data, 'lcojAO-qfLE4YdDqV')
      .then((response) => {
        setValues((prevValues) => ({
          ...prevValues,
          Step6: {
            idCustomer: '',
            confirmationMessage: "Un de nos conseillers va vous contacter pour finaliser votre réservation. <br> Vous pouvez également nous contacter au 05 59 52 75 85. <br><br> À très vite dans l’un de nos hôtels."
          },
        }));
      }, (err) => {
        setValues((prevValues) => ({
          ...prevValues,
          Step6: {
            idCustomer: '',
            confirmationMessage: 'Erreur lors de l\'envoie du mail.'
          },
        }));
      });
  }

  useDocumentTitle(`Coordonées et confirmation de la reservation - ${values.Hotel.name}`);
  return (

    <div className="select-zone">
      <Center>
        {values.Hotel.id !== 1 ? (
          <Title order={3}>Contact et détails</Title>
        ) : (
          <>
            {values.Step6.confirmationMessage ? (
              <Title order={3}>Merci pour votre demande de devis ! </Title>
            ) : (
              <Title order={3}>Demande de devis </Title>
            )}
          </>
        )}
      </Center>



      {values.Step6.confirmationMessage ? (
        <>
          <Center>
            <Text fz="xl" my="xl" align="center" dangerouslySetInnerHTML={{ __html: values.Step6.confirmationMessage }} />
            <img
              src={`https://messenger-services.com/pixel?accountID=971&step=Booking&amount=${values.TotalPrice}&currency=EUR&property=${values.Hotel.name}&checkin=${values.Date.StartUtc}&checkout=${values.Date.EndUtc}`}
              width="1"
              height="1"
            />
          </Center>
          <Center>
            <Anchor href="/">
              Accueil
            </Anchor>
          </Center>
          <Space h="xl" />
          <Space h="xl" />
        </>


      ) : (
        <>
          {values.Hotel.id !== 1 ? (
            <form onSubmit={form.onSubmit(() => { SubmitButton() })}>
              <Container>
                {values.Step1.NbCuriste === "2" || values.Step1.Accompagnant === 1 ? (
                  <Title order={2}>Curiste 1</Title>
                ) : null}
                <TextInput
                  withAsterisk
                  my="xl"
                  label="Nom"
                  placeholder="Nom"
                  {...form.getInputProps('name')}
                />

                <TextInput
                  withAsterisk
                  my="xl"
                  label="Prénom"
                  placeholder="Prénom"
                  {...form.getInputProps('fisrtname')}
                />

                <TextInput
                  withAsterisk
                  my="xl"
                  type="tel"
                  label="Téléphone"
                  placeholder="Téléphone"
                  {...form.getInputProps('phone')}
                />

                <TextInput
                  withAsterisk
                  my="xl"
                  label="E-mail"
                  placeholder="E-mail"
                  {...form.getInputProps('email')}
                />
                {values.Step1.NbCuriste === "2" ? (
                  <Title order={2}>Curiste 2</Title>
                ) : values.Step1.Accompagnant === 1 ? (
                  <Title order={2}>Accompagnant</Title>
                ) : null}

                {values.Step1.NbCuriste === "2" || values.Step1.Accompagnant === 1 ? (
                  <>
                    <TextInput
                      withAsterisk
                      required
                      my="xl"
                      label="Nom"
                      placeholder="Nom"
                      {...form.getInputProps('name2')}
                    />

                    <TextInput
                      withAsterisk
                      required
                      my="xl"
                      label="Prénom"
                      placeholder="Prénom"
                      {...form.getInputProps('fisrtname2')}
                    />

                    <TextInput
                      withAsterisk
                      required
                      my="xl"
                      label="E-mail"
                      placeholder="E-mail"
                      type="email"
                      {...form.getInputProps('email2')}
                    />
                  </>
                ) : null}

                <Checkbox
                  my="xl"
                  label={
                    <>
                      J'ai lu et j'accepte les{' '}
                      <Anchor href="https://www.biarritz-thalasso.com/content/3-conditions-generales-de-vente" target="_blank" className="main-color">
                        termes et conditions.
                      </Anchor>
                    </>
                  }
                  {...form.getInputProps('termes')}
                />
              </Container>

              <Space h="xl" />

              <Group position="center" my="lg">
                <Button className={"night-nav-active"} size="md" type="submit">
                  {isLoading ? <Loader /> : "Confirmer votre réservation"}
                </Button>
              </Group>
            </form>
          ) : (
            <form onSubmit={form.onSubmit(() => { SendEmail() })}>
              <TextInput
                withAsterisk
                my="xl"
                label="Nom"
                placeholder="Nom"
                {...form.getInputProps('name')}
              />

              <TextInput
                withAsterisk
                my="xl"
                label="Prénom"
                placeholder="Prénom"
                {...form.getInputProps('fisrtname')}
              />

              <TextInput
                withAsterisk
                my="xl"
                type="tel"
                label="Téléphone"
                placeholder="Téléphone"
                {...form.getInputProps('phone')}
              />

              <TextInput
                withAsterisk
                my="xl"
                label="E-mail"
                placeholder="E-mail"
                {...form.getInputProps('email')}
              />

              <Checkbox
                my="xl"
                label={
                  <>
                    J'ai lu et j'accepte les{' '}
                    <Anchor href="https://www.biarritz-thalasso.com/content/3-conditions-generales-de-vente" target="_blank" className="main-color">
                      termes et conditions.
                    </Anchor>
                  </>
                }
                {...form.getInputProps('termes')}
              />

              <Space h="xl" />

              <Group position="center" my="lg">
                <Button className={"night-nav-active"} size="md" type="submit">
                  {isLoading ? <Loader /> : "Envoyer la demande"}
                </Button>
              </Group>
            </form>
          )}
        </>
      )
      }

      {/* Bouton suivant */}
      {values.Step6.confirmationMessage ? (
        null
      ) : (
        <Group position="center" my="xl">
          <Button
            className={"step-btn-previous"}
            onClick={() => props.handleStepChange(props.active - 1)}
          >
            ← Retour
          </Button>
        </Group>
      )}

    </div>
  );
};
export default Step6;
