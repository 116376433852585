/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Divider, Grid, Space, Stepper } from "@mantine/core";
import { ValuesProvider } from "./ValuesContext";

import roomCategoryDataProvider from "./DataProvider/roomCategoryDataProvider";
import productDataProvider from "./DataProvider/productDataProvider";

import RecapCard from "./components/RecapCard";
import Step1 from "./components/StepComponents/Step1";
import Step2 from "./components/StepComponents/Step2";
import Step3 from "./components/StepComponents/Step3";
import Step4 from "./components/StepComponents/Step4";
import Step5 from "./components/StepComponents/Step5";
import Step6 from "./components/StepComponents/Step6";
import {
  getCategoryTitle,
} from "./components/StepComponents/utils";
import "./App.css";
import dayjs from "dayjs";
import cureDataProvider from "./DataProvider/cureDataProvider";
import categoryCureDataProvider from "./DataProvider/categoryCureDataProvider";
import datesPriceDataProvider from "./DataProvider/datesPriceDataProvider";
import { getConstants } from "./constants";
export const ROOM_CATEGORY_NAME = "fr-FR";

const Cart = () => {
  let idCure: any;

  const location = useLocation();
  try {
    idCure = location.state.id;
  } catch (error) {
    window.location.href = "/";
  }
  const cureName = location.state.name;
  const cure = location.state.cure;
  const mews_id = location.state.mews_id;
  const date = location.state.date;
  const nbNight = location.state.nbNight;
  const curePrice = location.state.price;
  const Hotel = location.state.Hotel;
  const Hotel_id = location.state.Hotel_id;

  const [active, setActive] = useState(0);
  const [highestStepVisited, setHighestStepVisited] = useState(active);

  const initialValues = {
    CureName: cureName,
    Price: curePrice,
    Description: cure,
    Hotel: Hotel,
    Hotel_id: Hotel.id,
    Discount: 0,
    discountmews: "",
    Step1: {
      NbCuriste: "",
      Accompagnant: 0,
      Label: "",
      Description: "",
    },
    Step2: {
      Value: "",
      CureName: "",
      Mews_id: "",
      Price: 0,
    },
    Step3: {
      RequestedCategoryId: "",
      RoomName: "",
      Price: [],
      selectedRoom: {}
    },
    Restauration: {
      ProductId: "",
      ProductName: "",
      ProductPrice: 0,
      ChargingMode: "",
    },
    Step6: {
      idCustomer: "",
      confirmationMessage: ""
    },
    AdditionalProducts: [],
    AdditionalProductsPrice: 0,
    Date: {
      StartUtc: date[0],
      EndUtc: date[1],
    },
    CurePrice: curePrice,
    TotalPrice: curePrice,
    IdCure: idCure,
    Mews_id: mews_id,
    nbNight: nbNight
  };

  const [roomData, setRoomData] = useState<any>([]);
  const [mydiscount, setDiscount] = useState<any>([]);
  const [discountmews, setdiscountmews] = useState<any>([]);
  const [finish, setFinish] = useState<boolean>(false);
  const [productData, setProductData] = useState<any>([]);

  const [cureList, setCureList] = useState<any[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [cureOption, setCureOption] = useState<any[]>([]);
  const [roomCategoryPrice, setRoomCategoryPrice] = useState<any>();
  const [allProduct, setAllProduct] = useState<any[]>([]);
  const [Petitdej, setPetitdej] = useState<any[]>([]);

  function getDatesBetween(startDate: Date, endDate: Date) {
    let dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }


  const fetchRoomData = async (constants: { Service_Id: any; petit_dejeuner_id?: string; rate_id?: string; categorie_id?: string; client_token?: string; access_token?: string; }) => {
    try {
      const endDate = new Date(initialValues.Date.EndUtc);
      const startDate = new Date(initialValues.Date.StartUtc);

      const responses = await roomCategoryDataProvider.getAvailability("resources", constants.Service_Id, initialValues.Date.StartUtc, endDate.toString(), constants);

      const rooms = await datesPriceDataProvider.getAllRoomCategory("allRoom");

      const updatedResponses: any[] = [];

      endDate.setDate(endDate.getDate() - 1);

      for (const response of responses) {

        const myroom = rooms.resultat.find((room: any) => room.mews_id.trim() === response.Id);

        if (myroom) {

          let price = await datesPriceDataProvider.getPriceByDate("datePrice", myroom.id, initialValues.Date.StartUtc, endDate.toString(), idCure);
          price = price[0]

          let discounts = await datesPriceDataProvider.getDiscount();

          discounts.resultat.forEach((discount: { hotel_id: string; start_date: string | number | Date; end_date: string | number | Date; dicount_percentage: number; mews_id: string }) => {
            const discountStartDate = new Date(discount.start_date);
            const discountEndDate = new Date(discount.end_date);

            if (discount.hotel_id === Hotel_id && !((discountStartDate <= startDate && discountEndDate <= startDate) &&
              (discountStartDate <= endDate && discountEndDate <= endDate))) {
              setDiscount(discount.dicount_percentage)
              setdiscountmews(discount.mews_id)
              const discountDates = getDatesBetween(discountStartDate, discountEndDate)
              const stayDates = getDatesBetween(startDate, endDate)


              stayDates.forEach((stayDate, index) => {
                if (discountDates.some(discountDate => discountDate.getDate() === stayDate.getDate())) {
                  console.log((1 - discount.dicount_percentage / 100))

                  price.prices_single[index] = Number(price.prices_single[index]) * (1 - discount.dicount_percentage / 100);
                  price.price_two_curists[index] = Number(price.price_two_curists[index]) * (1 - discount.dicount_percentage / 100);
                  price.price_curist_companion[index] = Number(price.price_curist_companion[index]) * (1 - discount.dicount_percentage / 100);
                }
              });
            }

            console.log(updatedResponses)

          });

          const updatedResponsetest = { ...response, price };
          updatedResponses.push(updatedResponsetest);
        }

      }

      setRoomData(updatedResponses);

    } catch (error) {
      console.error("Error fetching room data: ", error);
    }
  };


  // Recupere les produits de mews present dans notre BD
  const fetchProductData = async (constants: { Service_Id: string; petit_dejeuner_id: string; rate_id: string; categorie_id: string; client_token: string; access_token: string; }) => {
    try {
      const productResponse = await productDataProvider.getProduct("product", constants.Service_Id, constants);
      const myproducts = await productDataProvider.getAllProductsBL("allProduct");


      const updatedResponses: any[] = [];

      for (const product of myproducts.resultat) {
        for (const response of productResponse.Products) {
          if (product.mews_id.trim() === response.Id) {

            if (response.ImageIds[0]) {
              const myImage = await roomCategoryDataProvider.getImage(response.ImageIds[0], constants)
              response.imageUrl = myImage.ImageUrls[0].Url;
            }
            updatedResponses.push(response);
          }
        }
      }

      setProductData(updatedResponses);
    } catch (error) {
      console.log(error);
    }
  };

  let room_cat_id = 1;

  const fetchData = async (constants: { Service_Id: string; petit_dejeuner_id: any; rate_id?: string; categorie_id?: string; client_token?: string; access_token?: string; }) => {
    try {
      const [
        curesResponse,
        categoryResponse,
        cureOptionResponse,
        roomCategoyResponse,
        productListResponse
      ] = await Promise.all([
        cureDataProvider.getCureList("cures"),
        categoryCureDataProvider.getCategoryNight("nights"),
        cureDataProvider.getCureOption("option"),
        datesPriceDataProvider.getPriceByRoomCategory("roomPrice", room_cat_id),
        productDataProvider.getProduct("product", constants.Service_Id, constants)
      ]);

      const filteredCures = curesResponse.resultat.filter(
        (cure: any) => cure.nights === nbNight && cure.hotel_id == Hotel_id
      );

      setCureList(filteredCures);
      setCategory(categoryResponse.resultat);
      setCureOption(cureOptionResponse.resultat);
      setRoomCategoryPrice(roomCategoyResponse.base_rate);
      setAllProduct(productListResponse.Products)
      setPetitdej(productListResponse.Products.find((item: { Id: string; }) => item.Id === constants.petit_dejeuner_id))
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const constants = getConstants(Hotel_id);
    fetchRoomData(constants);
    fetchProductData(constants);
    fetchData(constants);
  }, []);

  const matchingDataAndCureOption = cureList.map((dataItem: any) => {

    const matchedCureOption = cureOption.filter(
      (cureOptionItem: any) => cureOptionItem.id === dataItem.id
    );
    const matchedcategoryNight = category.filter(
      (categoryItem: any) => categoryItem.id === dataItem.category_id
    );
    const category_title = getCategoryTitle(matchedcategoryNight);

    const priceCure = allProduct.find((product: any) => product.Id === dataItem.mews_id);

    if (priceCure) {
      const minPrice = priceCure.Pricing.Value.GrossValue;

      return {
        ...dataItem,
        ...matchedCureOption,
        category_title,
        minPrice: minPrice !== 0 ? minPrice : ((roomCategoryPrice * nbNight).toFixed()), // Utilisez le prix de la cure si minPrice est null
      };

    }


  });
  const handleStepChange = useCallback((nextStep: number) => {

    if (nextStep === 9) {
      setHighestStepVisited(1);
      return;
    } else if (nextStep > 5 || nextStep < 0) {
      return;
    }

    setActive(nextStep);

    setHighestStepVisited((hSC) => Math.max(hSC, nextStep));


  }, []);

  // Allow the user to freely go back and forth between visited steps.
  const shouldAllowSelectStep = (step: number) => {
    if (finish === true) {
      return false;
    }
    return highestStepVisited >= step && active !== step;
  };

  return (
    <>
      <ValuesProvider initialValues={initialValues}>
        <Divider my="sm" />
        <Container size="xl" px="xl">
          <Space h="xl" />
          <Space h="xl" />
          <Grid>
            <Grid.Col xl={4} md={4} lg={4}>
              <RecapCard date={date} nbNight={nbNight} active={active} />
            </Grid.Col>
            <Grid.Col xl={8} md={8} lg={8}>
              <Stepper
                color="var(--main-color)"
                active={active}
                onStepClick={setActive}
                breakpoint="sm"
                size="sm"
              >
                <Stepper.Step
                  className={
                    active === 0
                      ? "stepper-block-active"
                      : "stepper-block-inline"
                  }
                  label="participants"
                  allowStepSelect={shouldAllowSelectStep(0)}
                >
                  <Step1 active={active} initialValues={initialValues} handleStepChange={handleStepChange} mydiscount={mydiscount} discountmews={discountmews} />
                </Stepper.Step>
                <Stepper.Step
                  className={
                    active === 1
                      ? "stepper-block-active"
                      : active > 1
                        ? "stepper-block-inline"
                        : "stepper-block-inert"
                  }
                  label="programmes des soins"
                  allowStepSelect={shouldAllowSelectStep(1)}
                >
                  <Step2
                    heal={cure}
                    nbNight={nbNight}
                    idCure={idCure}
                    cureName={cureName}
                    cures={matchingDataAndCureOption}
                    active={active}
                    handleStepChange={handleStepChange}
                    Petitdej={Petitdej}
                  />
                </Stepper.Step>
                <Stepper.Step
                  className={
                    active === 2
                      ? "stepper-block-active"
                      : active > 2
                        ? "stepper-block-inline"
                        : "stepper-block-inert"
                  }
                  label="votre chambre"
                  {...(Hotel_id === 1 ? { allowStepSelect: false } : { allowStepSelect: shouldAllowSelectStep(2) })}

                >
                  <Step3
                    idCure={idCure}
                    active={active}
                    nbNight={nbNight}
                    handleStepChange={handleStepChange}
                    roomData={roomData}
                  />
                </Stepper.Step>
                <Stepper.Step
                  className={
                    active === 3
                      ? "stepper-block-active"
                      : active > 3
                        ? "stepper-block-inline"
                        : "stepper-block-inert"
                  }
                  label="options & personnalisation"

                  {...(Hotel_id === 1 ? { allowStepSelect: false } : { allowStepSelect: shouldAllowSelectStep(3) })}
                >
                  <Step4
                    Petitdej={Petitdej}
                    productData={productData}
                    active={active}
                    nbNight={nbNight}
                    handleStepChange={handleStepChange}
                    Hotel_id={Hotel_id}
                  />
                </Stepper.Step>
                <Stepper.Step
                  className={
                    active === 4
                      ? "stepper-block-active"
                      : active > 4
                        ? "stepper-block-inline"
                        : "stepper-block-inert"
                  }
                  label="récapitulatif"
                  allowStepSelect={shouldAllowSelectStep(4)}
                >
                  <Step5 active={active} night={nbNight} handleStepChange={handleStepChange} />
                </Stepper.Step>
                <Stepper.Step
                  className={
                    active === 5
                      ? "stepper-block-active"
                      : active > 5
                        ? "stepper-block-inline"
                        : "stepper-block-inert"
                  }
                  label="coordonnées"
                  allowStepSelect={shouldAllowSelectStep(5)}
                >
                  <Step6 active={active} handleStepChange={handleStepChange} setFinish={setFinish} Hotel_id={Hotel_id} />
                </Stepper.Step>
                <Stepper.Completed>
                  Completed, click back button to get to previous step
                </Stepper.Completed>
              </Stepper>
            </Grid.Col>
          </Grid>
        </Container>
      </ValuesProvider>
    </>
  );
};

export default Cart;