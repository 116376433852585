import { useEffect, useMemo, useState } from "react";
import DateBox from "./DateBox";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Carousel } from "@mantine/carousel";
import { Center, ScrollArea, Space, Title } from "@mantine/core";

interface DatesCarouselProps {
  priceBedroom: any;
  availability: any;
  selectedCure: any;
  dateSelect: any;
  handleDateSelected: any;
  allProduct: any;
  nbNight: number;
  Petitdej: Number;
}

const DateCarousel = ({
  priceBedroom,
  availability,
  selectedCure,
  dateSelect,
  handleDateSelected,
  allProduct,
  nbNight,
  Petitdej,
}: DatesCarouselProps) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const findPriceForDate = (key: number, cure: any) => {
    const priceCure = cure.Mews_id && (allProduct.find((product: any) => product.Id === cure.Mews_id));
    const defaultPrice = priceBedroom && (Number(priceBedroom[key]) * nbNight + priceCure.Pricing.Value.GrossValue * nbNight + (Number(Petitdej) * nbNight));
    return defaultPrice && (defaultPrice.toString());
  };

  dayjs.locale("fr");

  const lastDayOfYear = dayjs().endOf("year");
  let allDates: any = [];
  function calculateDatesByMonth() {
    let currentDate = dayjs().add(7, "day");
    while (currentDate.isBefore(lastDayOfYear) || currentDate.isSame(lastDayOfYear)) {
      const date = currentDate.toISOString();
      allDates = allDates.concat(date);
      currentDate = currentDate.add(1, "day");
    }

    const datesByMonth: { [key: string]: string[] } = {};
    allDates.forEach((date: string) => {
      const month = dayjs(date).format("MMMM YYYY");
      if (!datesByMonth[month]) {
        datesByMonth[month] = [];
      }
      datesByMonth[month] = datesByMonth[month].concat(date);
    });
    return datesByMonth;
  }

  // Modifie la taille de la dateBox selon la taille de l ecran
  useEffect(() => {
    const updateMaxHeight = () => {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      setMaxHeight(windowHeight);
    };



    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);
    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, []);

  const datesByMonth = useMemo(() => calculateDatesByMonth(), []);
  let monindex = 0;
  const slides = Object.keys(datesByMonth).map((month, index) => {
    const dates = datesByMonth[month];

    const dateBoxes = dates.map((date: any, key: number) => {
      const currentId = monindex++;
      let price = findPriceForDate(currentId, selectedCure);
      let full = false;

      if (selectedCure.Hotel.id !== 1) {

        for (let i = 0; i < Number(selectedCure.NbNight - 1); i++) {
          full = availability.every((avai: { Availabilities: number[] }) => avai.Availabilities[currentId + i] === 0);
          if (full) {
            break;
          }
        }

        if (full) {
          price = 'Non disponible';
        }
      }

      return (
        <DateBox
          key={currentId}
          index={currentId}
          date={date}
          dateSelect={dateSelect}
          handleDateSelected={handleDateSelected}
          price={price.toString()}
        />
      );
    });

    return (
      <Carousel.Slide key={index}>
        <Center>
          <Title className="card-date-title" order={6}>
            {selectedCure.NbNight === 1 ? (
              <>
                {month} - {selectedCure.NbNight} nuit
              </>
            ) : (
              <>
                {month} - {selectedCure.NbNight} nuits
              </>
            )}

          </Title>
        </Center>
        <Space h="xl" />
        <ScrollArea.Autosize maxHeight={(maxHeight * 0.9) - 400} mx="auto">
          {dateBoxes}
        </ScrollArea.Autosize>
      </Carousel.Slide>
    );
  });

  return <Carousel>{slides}</Carousel>;
};

export default DateCarousel;
