import { useEffect } from 'react';

const useDocumentTitle = (title: string): void => {
  useEffect(() => {
    document.title = title;

    return () => {
      document.title = 'Belha Collection'; // Vous pouvez définir le titre par défaut ici
    };
  }, [title]);
};

export default useDocumentTitle;