const MEWS_API_BASE_URL = process.env.REACT_APP_MEWS_API_BASE_URL;
const MEWS_CLIENT = process.env.REACT_APP_MEWS_CLIENT;

const customerMewsDataprovider = {
  // Recuperer un customer solon son email
  getOneCustomer: async (email: string, constants: any) => {
    const limitation = { Count: 10 };
    const emaillist = [];
    emaillist.push(email);

    const customer = await fetch(`${MEWS_API_BASE_URL}/customers/getAll`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ClientToken: constants.client_token,
        AccessToken: constants.access_token,
        Client: MEWS_CLIENT,
        Emails: emaillist,
        Limitation: limitation,
      }),
    });

    return customer.json();
  },

  createCustomer: async (
    firstname: string,
    name: string,
    email: string,
    phone: string,
    constants: any
  ) => {
    const customer = await fetch(`${MEWS_API_BASE_URL}/customers/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ClientToken: constants.client_token,
        AccessToken: constants.access_token,
        Client: MEWS_CLIENT,
        OverwriteExisting: false,
        FirstName: firstname,
        LastName: name,
        Email: email,
        Phone: phone,
      }),
    });

    return customer.json();
  },

  createReservation: async (
    categorieId: string,
    rateId: string,
    nbCuristes: number,
    serviceId: String,
    startUTC: string,
    endUTC: string,
    product: any,
    TimeUnitPrices: any,
    firstCustomer: any,
    TwoCustomer: any,
    firstCure: string,
    secondCure: string,
    constants: any
  ) => {
    let note = "";
    if (firstCustomer && firstCure) {
      note =
        firstCustomer.FirstName +
        " " +
        firstCustomer.LastName +
        " : " +
        firstCure;
    }

    if (TwoCustomer && secondCure) {
      note =
        note +
        " \n" +
        TwoCustomer.FirstName +
        " " +
        TwoCustomer.LastName +
        " : " +
        secondCure;
    }

    const requestBody = {
      ClientToken: constants.client_token,
      AccessToken: constants.access_token,
      Client: MEWS_CLIENT,
      ServiceId: serviceId,
      GroupId: null,
      GroupName: null,
      SendConfirmationEmail: true,
      Reservations: [
        {
          StartUtc: startUTC,
          EndUtc: endUTC,
          ReleasedUtc: null,
          CustomerId: firstCustomer.Id,
          RequestedCategoryId: categorieId,
          RateId: rateId,
          TravelAgencyId: null,
          CompanyId: null,
          Notes: note,
          TimeUnitAmount: null,
          TimeUnitPrices: TimeUnitPrices,
          PersonCounts: [
            {
              AgeCategoryId: constants.AgeCategoryId,
              Count: nbCuristes,
            },
          ],
          ProductOrders: product,
          AvailabilityBlockId: null,
        },
      ],
    };

    console.log(requestBody)

    const resa = await fetch(`${MEWS_API_BASE_URL}/reservations/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    return resa.json();
  },

  addCompanion: async (
    ReservationId: string,
    CustomerId: string,
    constants: any
  ) => {
    const companion = await fetch(
      `${MEWS_API_BASE_URL}/reservations/addCompanion`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ClientToken: constants.client_token,
          AccessToken: constants.access_token,
          Client: MEWS_CLIENT,
          ReservationId: ReservationId,
          CustomerId: CustomerId,
        }),
      }
    );

    return companion.json();
  },

  createPaymentRequest: async (
    AccountId: string,
    value: number,
    constants: any
  ) => {
    const expirationdate = new Date();
    expirationdate.setHours(expirationdate.getHours() + 24);
    const formattedDate = expirationdate.toISOString();

    const payment = await fetch(`${MEWS_API_BASE_URL}/paymentRequests/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ClientToken: constants.client_token,
        AccessToken: constants.access_token,
        Client: MEWS_CLIENT,
        PaymentRequests: [
          {
            AccountId: AccountId,
            Amount: {
              Currency: "EUR",
              Value: value,
            },
            Type: "Payment",
            Reason: "PaymentCardMissing",
            ExpirationUtc: formattedDate,
            Description: "Paiement 30% arrhes",
          },
        ],
      }),
    });

    return payment.json();
  },
};

export default customerMewsDataprovider;
